import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import 'react-phone-number-input/style.css'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
	<BrowserRouter>
	<React.StrictMode>
		<App />
	</React.StrictMode>   
  </BrowserRouter>,
)
