import React, { useState } from "react";
import styles from "./DataPolisies.module.css";
import { useForm, useWatch } from "react-hook-form";
import { toast } from "react-toastify";
import { API_URL } from "../../Сonstants";
// components

// img
import close from "../../image/Закрыть.svg";
import { useEffect } from "react";
import LastResult from "../LastResult/LastResult";

const DataPolisies = ({ closeForm, data }) => {
  //  useForm
  const {
    register,
    setValue,
    formState: { errors },
    handleSubmit,
    control,
    getValues,
  } = useForm({
    mode: "onChange",
  });

  const [loading, setLoading] = useState(false);
  // const [disabled, setDisabled] = useState(false);
  const [resultSum, setResultSum] = useState(0);
  const [client_code, setСlientCode] = useState("");
  const [lastResult, setLastResult] = useState(false);

  useEffect(() => {
    setValue("vehicle_insurant_fio", data.vehicle_insurant_fio);
    setValue("vehicle_insurant_pin", data.vehicle_insurant_pin);
    setValue("car_model", data.car_model);
    setValue("car_registration_mark", data.car_registration_mark);
    setValue("car_type", data.car_type);
    setValue("car_vin", data.car_vin);
    setValue("vehicle_passport", data.vehicle_passport);
    setValue("vehicle_start_date", data.vehicle_start_date);
    setValue("vehicle_end_date", data.vehicle_end_date);
    setValue("car_engine_capacity", data.car_engine_capacity);
    setValue("car_maximum_mass", data.car_maximum_mass);
    setValue("car_seats_count", data.car_seats_count);
    setValue("motor_power", data.motor_power);
    setValue("vehicle_driver_allowed_fio1", data.vehicle_driver_allowed_fio1);
    setValue("vehicle_driver_allowed_fio2", data.vehicle_driver_allowed_fio2);
    setValue("vehicle_driver_allowed_fio3", data.vehicle_driver_allowed_fio3);
    setValue("vehicle_driver_allowed_fio4", data.vehicle_driver_allowed_fio4);
    setValue("vehicle_driver_allowed_dob1", data.vehicle_driver_allowed_dob1);
    setValue("vehicle_driver_allowed_dob2", data.vehicle_driver_allowed_dob2);
    setValue("vehicle_driver_allowed_dob3", data.vehicle_driver_allowed_dob3);
    setValue("vehicle_driver_allowed_dob4", data.vehicle_driver_allowed_dob4);

    setValue(
      "vehicle_driver_allowed_experience_license1",
      data.vehicle_driver_allowed_experience_license1
    );
    setValue(
      "vehicle_driver_allowed_experience_license2",
      data.vehicle_driver_allowed_experience_license2
    );
    setValue(
      "vehicle_driver_allowed_experience_license3",
      data.vehicle_driver_allowed_experience_license3
    );
    setValue(
      "vehicle_driver_allowed_experience_license4",
      data.vehicle_driver_allowed_experience_license4
    );
    setValue(
      "vehicle_driver_allowed_experience_year1",
      data.vehicle_driver_allowed_experience_year1
    );
    setValue(
      "vehicle_driver_allowed_experience_year2",
      data.vehicle_driver_allowed_experience_year2
    );
    setValue(
      "vehicle_driver_allowed_experience_year3",
      data.vehicle_driver_allowed_experience_year3
    );
    setValue(
      "vehicle_driver_allowed_experience_year4",
      data.vehicle_driver_allowed_experience_year4
    );

    setValue("is_diagnostic_card_exist", data.is_diagnostic_card_exist);
    setValue(
      "is_registration_in_kyrgyzstan",
      data.is_registration_in_kyrgyzstan
    );
    setValue("client_code", data.client_code);
  }, [
    data.is_registration_in_kyrgyzstan,
    data.is_diagnostic_card_exist,
    data.vehicle_insurant_fio,
    data.vehicle_insurant_pin,
    data.car_engine_capacity,
    data.car_maximum_mass,
    data.car_model,
    data.car_registration_mark,
    data.car_seats_count,
    data.car_type,
    data.car_vin,
    data.motor_power,
    data.vehicle_passport,
    data.vehicle_start_date,
    data.vehicle_end_date,
    data.vehicle_driver_allowed_fio1,
    data.vehicle_driver_allowed_fio2,
    data.vehicle_driver_allowed_fio3,
    data.vehicle_driver_allowed_fio4,
    data.vehicle_driver_allowed_dob1,
    data.vehicle_driver_allowed_dob2,
    data.vehicle_driver_allowed_dob3,
    data.vehicle_driver_allowed_dob4,
    data.vehicle_driver_allowed_experience_license1,
    data.vehicle_driver_allowed_experience_license2,
    data.vehicle_driver_allowed_experience_license3,
    data.vehicle_driver_allowed_experience_license4,
    data.vehicle_driver_allowed_experience_year1,
    data.vehicle_driver_allowed_experience_year2,
    data.vehicle_driver_allowed_experience_year3,
    data.vehicle_driver_allowed_experience_year4,
    data.client_code,
  ]);
  console.log(data);
  // radio
  const type = [
    {
      id: 0,
      value: 1,
      radio: "Легковой",
    },
    {
      id: 1,
      value: 2,
      radio: "Электромобиль",
    },
    {
      id: 2,
      value: 3,
      radio: "Грузовое авто",
    },
    {
      id: 3,
      value: 4,
      radio: "Автобус",
    },
    {
      id: 4,
      value: 5,
      radio: "Мотоцикл",
    },
    {
      id: 5,
      value: 6,
      radio: "Троллейбусы",
    },
    {
      id: 6,
      value: 7,
      radio: "Рабочие техники",
    },
  ];
  const [typeCar, setTypeCar] = useState(type[0]);

  const handleClick = (index) => {
    const carI = type[index];
    setTypeCar(carI);
  };
  React.useEffect(() => {
    if (data.car_type) {
      setTypeCar({
        id: parseInt(data?.car_type),
        value: parseInt(data?.car_type),
      });
    }
  }, [data.car_type]);

  // sum
  const vehicle_doc_sequence = useWatch({
    control,
    name: "vehicle_doc_sequence",
  });
  const vehicle_start_date = useWatch({
    control,
    name: "vehicle_start_date",
  });
  const vehicle_end_date = useWatch({
    control,
    name: "vehicle_end_date",
  });

  // submit
  const onSubmit = (data) => {
    setLoading(true);
    console.log("is_registration_in_kyrgyzstan: do ", data);
    data = {
      ...data,
      is_diagnostic_card_exist:
        String(data.is_diagnostic_card_exist) === "true" ? true : false,
      is_registration_in_kyrgyzstan:
        String(data.is_registration_in_kyrgyzstan) === "true" ? true : false,
    };

    console.log("is_registration_in_kyrgyzstan after: ", data);

    if (data.vehicle_start_date && data.vehicle_end_date) {
      const startDate = new Date(data.vehicle_start_date);
      const endDate = new Date(data.vehicle_end_date);
      const daysDiff = (endDate - startDate) / (1000 * 3600 * 24);
      const yearsDiff = endDate.getFullYear() - startDate.getFullYear();
      if (daysDiff < 5 || yearsDiff > 1) {
        alert(
          "Дата окончания должна быть не менее чем на 5 дней больше даты начала и не более чем на 1 год больше"
        );
        return;
      }
 
    }

    // parseInt
    data.car_type = parseInt(data.car_type);
    data.car_engine_capacity = parseInt(data.car_engine_capacity);
    data.car_maximum_mass = parseInt(data.car_maximum_mass);
    data.car_seats_count = parseInt(data.car_seats_count);
    data.motor_power = parseInt(data.motor_power);

    // empty
    const emptyString = {};
    Object.keys(data).forEach((item) => {
      if (data[item] === "") {
        emptyString[item] = null;
      } else {
        emptyString[item] = data[item];
      }
    });
    // post
    var myHeaders = new Headers();
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(emptyString),
      redirect: "follow",
    };
    try {
      fetch(`${API_URL}/car_confirm_agent/`, requestOptions)
        .then((res) => {
          if (res?.status === 401) {
            setLoading(false);
            setLastResult(false);
            return toast.error("Неправильный логин или пароль");
          }
          if (res?.status >= 400) {
            setLoading(false);
            setLastResult(false);
            return toast.error(
              "Форма не отправлена! Возможно некорректные данные."
            );
          }
          return res.json();
        })
        .then((response) => {
          setLoading(false);
          setResultSum(response.sum);
          setСlientCode(response.client_code);
          setLastResult(true);

          if (!response) {
            setLoading(false);
            setLastResult(false);

            return toast.error("Форма не отправлена");
          }
        })
        .catch((err) => {
          setLoading(false);
          setLastResult(false);
          toast.error("Форма не отправлена");
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (e) {
      setLoading(false);
      setLastResult(false);
      toast.error("Ошибка с сервером");
    }
  };

  // firefox
  const checkIfNumber = (event) => {
    const regex = new RegExp(
      /(^\d*$)|(Backspace|Tab|Delete|ArrowLeft|ArrowRight)/
    );
    return !event.key.match(regex) && event.preventDefault();
  };
  // upperCase
  const handleInput = (event) => {
    event.target.value = event.target.value.toUpperCase();
  };

  const [isDriverInputSecondFilled, setIsDriverInputSecondFilled] =
    useState(false);
  const [isDriverInputThirdFilled, setIsDriverInputThirdFilled] =
    useState(false);
  const [isDriverInputFourthFilled, setIsDriverInputFourthFilled] =
    useState(false);

  const validateUniqueDriverLicenses = () => {
    const driverLicenses = [
      getValues("vehicle_driver_allowed_experience_license1") || "",
      getValues("vehicle_driver_allowed_experience_license2") || "",
      getValues("vehicle_driver_allowed_experience_license3") || "",
      getValues("vehicle_driver_allowed_experience_license4") || "",
    ];

    // Проверяем, что минимум два номера заполнены перед выполнением проверки уникальности
    const filledLicenses = driverLicenses.filter(
      (license) => license.trim() !== ""
    );
    if (filledLicenses.length >= 2) {
      const uniqueLicenses = new Set(
        filledLicenses.map((license) => license.trim())
      );

      return (
        uniqueLicenses.size === filledLicenses.length ||
        "Номера водительских прав не должны повторяться"
      );
    }

    // Если не заполнено два номера, то считаем валидацию успешной
    return true;
  };

  return (
    <div className={styles.window}>
      <div className="wrapper">
        <div className={styles.polis}>
          <div className={styles.windowHead}>
            <div>
              <h1>
                Данные полиса: <span>{data.client_code}</span>
              </h1>
            </div>
            <img onClick={closeForm} src={close} alt="close" />
          </div>

          <form onSubmit={handleSubmit(onSubmit)}>
            <div className={styles.polisBlock}>
              <div className={styles.data}>
                <div className={styles.dataContent}>
                  <div className={styles.headText}>
                    <h1>Страхователь</h1>
                  </div>
                  <div className={styles.dataBlock}>
                    <div className={styles.dataBox}>
                      <div className={styles.dataItem}>
                        <label htmlFor="vehicle_insurant_fio">
                          Фамилия Имя Отчество
                        </label>

                        <input
                          id={styles.vehicle_insurant_fio}
                          type="text"
                          defaultValue={data.vehicle_insurant_fio}
                          {...register("vehicle_insurant_fio", {
                            required: "Поле обязательно к заполнению",
                            pattern: {
                              value: /^[\WА-Яа-я]+$/i,
                              message:
                                "Введите только на кириллице! Символы и цифры нельзя!",
                            },
                            minLength: {
                              value: 3,
                              message: "Минимум 3 букв",
                            },
                          })}
                        />

                        <div>
                          {errors?.vehicle_insurant_fio && (
                            <p
                              style={{
                                color: "red",
                                marginTop: "10px",
                                marginBottom: "10px",
                              }}
                            >
                              {errors?.vehicle_insurant_fio?.message ||
                                "Ошибка"}
                            </p>
                          )}
                        </div>
                      </div>
                      <div className={styles.dataItem}>
                        <label htmlFor="vehicle_insurant_pin">ИНН</label>
                        <input
                          onInput={(e) => {
                            if (e.target.value.length > e.target.maxLength)
                              e.target.value = e.target.value.slice(
                                0,
                                e.target.maxLength
                              );
                          }}
                          onKeyDown={(event) => checkIfNumber(event)}
                          maxLength={14}
                          id={styles.vehicle_insurant_pin}
                          type="number"
                          defaultValue={data.vehicle_insurant_pin}
                          {...register("vehicle_insurant_pin", {
                            required: "Поле обязательно к заполнению",

                            minLength: {
                              value: 14,
                              message: "Введите 14 цифр",
                            },
                            maxLength: {
                              value: 14,
                              message: "Неккоректный ИНН",
                            },
                          })}
                        />

                        <div>
                          {errors?.vehicle_insurant_pin && (
                            <p
                              style={{
                                color: "red",
                                marginTop: "10px",
                                marginBottom: "10px",
                              }}
                            >
                              {errors?.vehicle_insurant_pin?.message ||
                                "Ошибка"}
                            </p>
                          )}
                        </div>
                      </div>
                      <div className={styles.dataItem}>
                        <label htmlFor="car_model">Марка и модель</label>
                        <input
                          id={styles.car_model}
                          type="text"
                          defaultValue={data.car_model}
                          {...register("car_model", {
                            required: "Поле обязательно к заполнению",
                          })}
                        />

                        <div>
                          {errors?.car_model && (
                            <p
                              style={{
                                color: "red",
                                marginTop: "10px",
                                marginBottom: "10px",
                              }}
                            >
                              {errors?.car_model?.message || "Ошибка"}
                            </p>
                          )}
                        </div>
                      </div>
                      <div className={styles.dataItem}>
                        <label htmlFor="vehicle_start_date">
                          Дата начала страхования
                        </label>
                        <input
                          id={styles.vehicle_start_date}
                          type="date"
                          defaultValue={data.vehicle_start_date}
                          {...register("vehicle_start_date", {
                            required: "Поле обязательно к заполнению",
                          })}
                        />

                        <div>
                          {errors?.vehicle_start_date && (
                            <p
                              style={{
                                color: "red",
                                marginTop: "10px",
                                marginBottom: "10px",
                              }}
                            >
                              {errors?.vehicle_start_date?.message || "Ошибка"}
                            </p>
                          )}
                        </div>
                        {vehicle_start_date >= vehicle_end_date ? (
                          <p
                            style={{
                              color: "red",
                              marginTop: "10px",
                              marginBottom: "10px",
                            }}
                          >
                            {
                              "Нельзя делать дату окончание меньше либо равным даты начало"
                            }
                          </p>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>

                    <div className={styles.dataBox}>
                      <div className={styles.dataItem}>
                        <label htmlFor="car_registration_mark">Гос.номер</label>
                        <input
                          id={styles.car_registration_mark}
                          type="text"
                          onInput={handleInput}
                          defaultValue={data.car_registration_mark}
                          {...register("car_registration_mark", {
                            required: "Поле обязательно к заполнению",
                            // pattern: {
                            //   value: /^[a-zA-Z0-9][a-zA-Z0-9]*$/,
                            //   message: "Введите на латинице",
                            // },
                          })}
                        />

                        <div>
                          {errors?.car_registration_mark && (
                            <p
                              style={{
                                color: "red",
                                marginTop: "10px",
                                marginBottom: "10px",
                              }}
                            >
                              {errors?.car_registration_mark?.message ||
                                "Ошибка"}
                            </p>
                          )}
                        </div>
                      </div>
                      <div className={styles.dataItem}>
                        <label htmlFor="car_vin">Номер кузова (VIN)</label>
                        <input
                          onInput={(e) => {
                            e.target.value = e.target.value.toUpperCase();
                          }}
                          id={styles.car_vin}
                          type="text"
                          placeholder="0хххх00хххх000000"
                          {...register("car_vin", {
                            required: "Поле обязательно к заполнению",
                            pattern: {
                              value: /^[a-zA-Z0-9][a-zA-Z0-9]*$/,
                              message: "Введите на латинице",
                            },
                          })}
                        />

                        <div>
                          {errors?.car_vin && (
                            <p
                              style={{
                                color: "red",
                                marginTop: "10px",
                                marginBottom: "10px",
                              }}
                            >
                              {errors?.car_vin?.message || "Ошибка"}
                            </p>
                          )}
                        </div>
                      </div>
                      <div className={styles.dataItem}>
                        <label htmlFor="vehicle_passport">
                          Номер техпаспорта
                        </label>
                        <input
                          id={styles.vehicle_passport}
                          type="text"
                          onInput={handleInput}
                          defaultValue={data.vehicle_passport}
                          {...register("vehicle_passport", {
                            required: "Поле обязательно к заполнению",
                          })}
                        />

                        <div>
                          {errors?.vehicle_passport && (
                            <p
                              style={{
                                color: "red",
                                marginTop: "10px",
                                marginBottom: "10px",
                              }}
                            >
                              {errors?.vehicle_passport?.message || "Ошибка"}
                            </p>
                          )}
                        </div>
                      </div>
                      <div
                        style={{ marginBottom: 0 }}
                        className={styles.dataItem}
                      >
                        <label htmlFor="vehicle_end_date">
                          Дата окончания страхования
                        </label>

                        <input
                          id={styles.vehicle_end_date}
                          type="date"
                          defaultValue={data.vehicle_end_date}
                          {...register("vehicle_end_date", {
                            required: "Поле обязательно к заполнению",

                            message:
                              "Нельзя делать дату окончание меньше даты начало",
                          })}
                        />
                        <div>
                          {errors?.vehicle_start_date && (
                            <p
                              style={{
                                color: "red",
                                marginTop: "10px",
                                marginBottom: "10px",
                              }}
                            >
                              {errors?.vehicle_start_date?.message || "Ошибка"}
                            </p>
                          )}
                        </div>

                        {/* <div>
                          {vehicle_start_date >= vehicle_end_date ? (
                            <p
                              style={{
                                color: "red",
                                marginTop: "10px",
                                marginBottom: "10px",
                              }}
                            >
                              {
                                "Нельзя делать дату окончание меньше либо равным даты начало"
                              }
                            </p>
                          ) : (
                            ""
                          )}
                        </div> */}
                      </div>
                    </div>
                  </div>
                  <div className={styles.dataBlock_2}>
                    <div
                      className={styles.dataItem + " " + styles.dataItemCarType}
                    >
                      <label htmlFor="car_type">Тип транспорта:</label>
                      <select
                        {...register("car_type", {
                          required: "Поле обязательно к заполнению",
                        })}
                        value={typeCar.value} // Установить значение по умолчанию на основе данных из ответа
                        onChange={(e) => handleClick(e.target.selectedIndex)}
                        className={styles.select}
                      >
                        {type.map((data, i) => (
                          <option key={i} value={data.value}>
                            {data.radio}
                          </option>
                        ))}
                      </select>

                      <div>
                        {errors?.car_type && (
                          <p
                            style={{
                              color: "red",
                              marginTop: "10px",
                              marginBottom: "10px",
                            }}
                          >
                            {errors?.car_type?.message || "Ошибка"}
                          </p>
                        )}
                      </div>
                    </div>
                    {typeCar.value === 1 && (
                      <div
                        style={{ marginBottom: 0 }}
                        className={styles.dataItem}
                      >
                        <label htmlFor="car_engine_capacity">
                          объем двигателя легкового авто (см3)
                        </label>
                        <input
                          onKeyDown={(event) => checkIfNumber(event)}
                          id={styles.car_engine_capacity}
                          type="number"
                          defaultValue={data.car_engine_capacity}
                          {...register("car_engine_capacity")}
                        />
                        <div>
                          {errors?.car_engine_capacity && (
                            <p
                              style={{
                                color: "red",
                                marginTop: "10px",
                                marginBottom: "10px",
                              }}
                            >
                              {errors?.car_engine_capacity?.message || "Ошибка"}
                            </p>
                          )}
                        </div>
                      </div>
                    )}
                    {typeCar.value === 2 && (
                      <div
                        style={{ marginBottom: 0 }}
                        className={styles.dataItem}
                      >
                        <label htmlFor="motor_power">
                          мощность электродвигателя (кВт)
                        </label>
                        <input
                          onKeyDown={(event) => checkIfNumber(event)}
                          id={styles.motor_power}
                          type="number"
                          defaultValue={data.motor_power}
                          {...register("motor_power")}
                        />

                        <div>
                          {errors?.motor_power && (
                            <p
                              style={{
                                color: "red",
                                marginTop: "10px",
                                marginBottom: "10px",
                              }}
                            >
                              {errors?.motor_power?.message || "Ошибка"}
                            </p>
                          )}
                        </div>
                      </div>
                    )}

                    {typeCar.value === 3 && (
                      <div
                        style={{ marginBottom: 0 }}
                        className={styles.dataItem}
                      >
                        <label htmlFor="car_maximum_mass">
                          Масса грузового авто (тонн)
                        </label>
                        <input
                          onKeyDown={(event) => checkIfNumber(event)}
                          id={styles.car_maximum_mass}
                          type="number"
                          defaultValue={data.car_maximum_mass}
                          {...register("car_maximum_mass")}
                        />

                        <div>
                          {errors?.car_maximum_mass && (
                            <p
                              style={{
                                color: "red",
                                marginTop: "10px",
                                marginBottom: "10px",
                              }}
                            >
                              {errors?.car_maximum_mass?.message || "Ошибка"}
                            </p>
                          )}
                        </div>
                      </div>
                    )}
                    {typeCar.value === 4 && (
                      <div
                        style={{ marginBottom: 0 }}
                        className={styles.dataItem}
                      >
                        <label htmlFor="car_seats_count">
                          Число пассажирских мест автобуса
                        </label>
                        <input
                          onKeyDown={(event) => checkIfNumber(event)}
                          id={styles.car_seats_count}
                          type="number"
                          defaultValue={data.car_seats_count}
                          {...register("car_seats_count")}
                        />

                        <div>
                          {errors?.car_seats_count && (
                            <p
                              style={{
                                color: "red",
                                marginTop: "10px",
                                marginBottom: "10px",
                              }}
                            >
                              {errors?.car_seats_count?.message || "Ошибка"}
                            </p>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className={styles.headText}>
                  <h1>Водители</h1>
                </div>
                <div className={styles.driversBlock}>
                  <div>
                    <div className={styles.numDriver}>
                      <h1>Водитель № 1</h1>
                    </div>
                    <div className={styles.driversBox}>
                      <div className={styles.driversItem}>
                        <label htmlFor="vehicle_driver_allowed_fio1">
                          ФИО:
                        </label>
                        <input
                          id={styles.vehicle_driver_allowed_fio1}
                          type="text"
                          defaultValue={data.vehicle_driver_allowed_fio1}
                          {...register("vehicle_driver_allowed_fio1", {
                            required: "Поле обязательно к заполнению",

                            pattern: {
                              value: /^[\WА-Яа-я]+$/i,
                              message:
                                "Введите только на кириллице! Символы и цифры нельзя!",
                            },
                            minLength: {
                              value: 3,
                              message: "Минимум 3 букв",
                            },
                          })}
                        />

                        <div>
                          {errors?.vehicle_driver_allowed_fio1 && (
                            <p
                              style={{
                                color: "red",
                                marginTop: "10px",
                                marginBottom: "10px",
                              }}
                            >
                              {errors?.vehicle_driver_allowed_fio1?.message ||
                                "Ошибка"}
                            </p>
                          )}
                        </div>
                      </div>
                      <div className={styles.driversItem}>
                        <label htmlFor="vehicle_driver_allowed_dob1">
                          Дата рождения:
                        </label>
                        <input
                          id={styles.vehicle_driver_allowed_dob1}
                          type="date"
                          defaultValue={data.vehicle_driver_allowed_dob1}
                          {...register("vehicle_driver_allowed_dob1", {
                            required: "Поле обязательно к заполнению",
                          })}
                        />
                        <div>
                          {errors?.vehicle_driver_allowed_dob1 && (
                            <p
                              style={{
                                color: "red",
                                marginTop: "10px",
                                marginBottom: "10px",
                              }}
                            >
                              {errors?.vehicle_driver_allowed_dob1?.message ||
                                "Ошибка"}
                            </p>
                          )}
                        </div>
                      </div>
                      <div className={styles.driversItem}>
                        <label htmlFor="vehicle_driver_allowed_experience_year1">
                          Стаж:
                        </label>
                        <input
                          id={styles.vehicle_driver_allowed_experience_year1}
                          type="date"
                          defaultValue={
                            data.vehicle_driver_allowed_experience_year1
                          }
                          {...register(
                            "vehicle_driver_allowed_experience_year1",
                            {
                              required: "Поле обязательно к заполнению",
                            }
                          )}
                        />
                        <div>
                          {errors?.vehicle_driver_allowed_experience_year1 && (
                            <p
                              style={{
                                color: "red",
                                marginTop: "10px",
                                marginBottom: "10px",
                              }}
                            >
                              {errors?.vehicle_driver_allowed_experience_year1
                                ?.message || "Ошибка"}
                            </p>
                          )}
                        </div>
                      </div>
                      <div className={styles.driversItem}>
                        <label htmlFor="vehicle_driver_allowed_experience_license1">
                          № водит. прав:
                        </label>
                        <input
                          id={styles.vehicle_driver_allowed_experience_license1}
                          type="text"
                          onInput={handleInput}
                          defaultValue={
                            data.vehicle_driver_allowed_experience_year1
                          }
                          {...register(
                            "vehicle_driver_allowed_experience_license1",
                            {
                              required: "Поле обязательно к заполнению",

                              pattern: {
                                value: /^[a-zA-Z0-9][a-zA-Z0-9]*$/,

                                message: "Введите на латинице",
                              },
                            }
                          )}
                        />
                        <div>
                          {errors?.vehicle_driver_allowed_experience_license1 && (
                            <p
                              style={{
                                color: "red",
                                marginTop: "10px",
                                marginBottom: "10px",
                              }}
                            >
                              {errors
                                ?.vehicle_driver_allowed_experience_license1
                                ?.message || "Ошибка"}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div>
                    <div className={styles.numDriver}>
                      <h1>Водитель № 2</h1>
                    </div>
                    <div className={styles.driversBox}>
                      <div className={styles.driversItem}>
                        <label htmlFor="vehicle_driver_allowed_fio2">
                          ФИО:
                        </label>
                        <input
                          id={styles.vehicle_driver_allowed_fio2}
                          type="text"
                          defaultValue={data.vehicle_driver_allowed_fio2}
                          {...register("vehicle_driver_allowed_fio2", {
                            pattern: {
                              value: /^[\WА-Яа-я]+$/i,
                              message:
                                "Введите только на кириллице! Символы и цифры нельзя!",
                            },
                            minLength: {
                              value: 3,
                              message: "Минимум 3 букв",
                            },
                          })}
                          onChange={(e) => {
                            if (e.target.value.trim() !== "") {
                              setIsDriverInputSecondFilled(true);
                            } else {
                              setIsDriverInputSecondFilled(false);
                              setValue("vehicle_driver_allowed_fio2", null);
                              setValue("vehicle_driver_allowed_dob2", null);
                              setValue(
                                "vehicle_driver_allowed_experience_year2",
                                null
                              );
                              setValue(
                                "vehicle_driver_allowed_experience_license2",
                                null
                              );
                            }
                          }}
                        />

                        <div>
                          {errors?.vehicle_driver_allowed_fio2 && (
                            <p
                              style={{
                                color: "red",
                                marginTop: "10px",
                                marginBottom: "10px",
                              }}
                            >
                              {errors?.vehicle_driver_allowed_fio2?.message ||
                                "Ошибка"}
                            </p>
                          )}
                        </div>
                      </div>
                      <div className={styles.driversItem}>
                        <label htmlFor="vehicle_driver_allowed_dob2">
                          Дата рождения:
                        </label>
                        <input
                          id={styles.vehicle_driver_allowed_dob2}
                          type="date"
                          defaultValue={data.vehicle_driver_allowed_dob2}
                          {...register("vehicle_driver_allowed_dob2", {
                            required: isDriverInputSecondFilled
                              ? "Поле обязательно к заполнению"
                              : undefined,
                          })}
                          readOnly={!isDriverInputSecondFilled}
                        />
                        <div>
                          {errors?.vehicle_driver_allowed_dob2 && (
                            <p
                              style={{
                                color: "red",
                                marginTop: "10px",
                                marginBottom: "10px",
                              }}
                            >
                              {errors?.vehicle_driver_allowed_dob2?.message ||
                                "Ошибка"}
                            </p>
                          )}
                        </div>
                      </div>
                      <div className={styles.driversItem}>
                        <label htmlFor="vehicle_driver_allowed_experience_year2">
                          Стаж:
                        </label>
                        <input
                          id={styles.vehicle_driver_allowed_experience_year2}
                          type="date"
                          defaultValue={
                            data.vehicle_driver_allowed_experience_year2
                          }
                          {...register(
                            "vehicle_driver_allowed_experience_year2",
                            {
                              required: isDriverInputSecondFilled
                                ? "Поле обязательно к заполнению"
                                : null,
                            }
                          )}
                          readOnly={!isDriverInputSecondFilled}
                        />
                        <div>
                          {errors?.vehicle_driver_allowed_experience_year2 && (
                            <p
                              style={{
                                color: "red",
                                marginTop: "10px",
                                marginBottom: "10px",
                              }}
                            >
                              {errors?.vehicle_driver_allowed_experience_year2
                                ?.message || "Ошибка"}
                            </p>
                          )}
                        </div>
                      </div>
                      <div className={styles.driversItem}>
                        <label htmlFor="vehicle_driver_allowed_experience_license2">
                          № водит. прав:
                        </label>
                        <input
                          id={styles.vehicle_driver_allowed_experience_license2}
                          type="text"
                          placeholder="00 00 000000"
                          onInput={handleInput}
                          defaultValue={
                            data.vehicle_driver_allowed_experience_license2
                          }
                          {...register(
                            "vehicle_driver_allowed_experience_license2",
                            {
                              required: isDriverInputSecondFilled
                                ? "Поле обязательно к заполнению"
                                : null,
                              pattern: {
                                value: /^[a-zA-Z0-9][a-zA-Z0-9]*$/,
                                message: "Введите на латинице",
                              },
                              validate: validateUniqueDriverLicenses,
                            }
                          )}
                          readOnly={!isDriverInputSecondFilled}
                        />
                        <div>
                          {errors?.vehicle_driver_allowed_experience_license2 && (
                            <p
                              style={{
                                color: "red",
                                marginTop: "10px",
                                marginBottom: "10px",
                              }}
                            >
                              {errors
                                ?.vehicle_driver_allowed_experience_license2
                                ?.message || "Ошибка"}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div>
                    <div className={styles.numDriver}>
                      <h1>Водитель № 3</h1>
                    </div>
                    <div className={styles.driversBox}>
                      <div className={styles.driversItem}>
                        <label htmlFor="vehicle_driver_allowed_fio3">
                          ФИО:
                        </label>
                        <input
                          id={styles.vehicle_driver_allowed_fio3}
                          type="text"
                          defaultValue={data.vehicle_driver_allowed_fio3}
                          {...register("vehicle_driver_allowed_fio3", {
                            pattern: {
                              value: /^[\WА-Яа-я]+$/i,
                              message:
                                "Введите только на кириллице! Символы и цифры нельзя!",
                            },
                            minLength: {
                              value: 3,
                              message: "Минимум 3 букв",
                            },
                          })}
                          onChange={(e) => {
                            if (e.target.value.trim() !== "") {
                              setIsDriverInputThirdFilled(true);
                            } else {
                              setIsDriverInputThirdFilled(false);
                              setValue("vehicle_driver_allowed_fio3", null);
                              setValue("vehicle_driver_allowed_dob3", null);
                              setValue(
                                "vehicle_driver_allowed_experience_year3",
                                null
                              );
                              setValue(
                                "vehicle_driver_allowed_experience_license3",
                                null
                              );
                            }
                          }}
                        />

                        <div>
                          {errors?.vehicle_driver_allowed_fio3 && (
                            <p
                              style={{
                                color: "red",
                                marginTop: "10px",
                                marginBottom: "10px",
                              }}
                            >
                              {errors?.vehicle_driver_allowed_fio3?.message ||
                                "Ошибка"}
                            </p>
                          )}
                        </div>
                      </div>
                      <div className={styles.driversItem}>
                        <label htmlFor="vehicle_driver_allowed_dob3">
                          Дата рождения:
                        </label>
                        <input
                          id={styles.vehicle_driver_allowed_dob3}
                          type="date"
                          {...register("vehicle_driver_allowed_dob3", {
                            required: isDriverInputThirdFilled
                              ? "Поле обязательно к заполнению"
                              : null,
                          })}
                          readOnly={!isDriverInputThirdFilled}
                        />
                        <div>
                          {errors?.vehicle_driver_allowed_dob3 && (
                            <p
                              style={{
                                color: "red",
                                marginTop: "10px",
                                marginBottom: "10px",
                              }}
                            >
                              {errors?.vehicle_driver_allowed_dob3?.message ||
                                "Ошибка"}
                            </p>
                          )}
                        </div>
                      </div>
                      <div className={styles.driversItem}>
                        <label htmlFor="vehicle_driver_allowed_experience_year3">
                          Стаж:
                        </label>
                        <input
                          id={styles.vehicle_driver_allowed_experience_year3}
                          defaultValue={
                            data.vehicle_driver_allowed_experience_year3
                          }
                          type="date"
                          {...register(
                            "vehicle_driver_allowed_experience_year3",
                            {
                              required: isDriverInputThirdFilled
                                ? "Поле обязательно к заполнению"
                                : null,
                            }
                          )}
                          readOnly={!isDriverInputThirdFilled}
                        />
                        <div>
                          {errors?.vehicle_driver_allowed_experience_year3 && (
                            <p
                              style={{
                                color: "red",
                                marginTop: "10px",
                                marginBottom: "10px",
                              }}
                            >
                              {errors?.vehicle_driver_allowed_experience_year3
                                ?.message || "Ошибка"}
                            </p>
                          )}
                        </div>
                      </div>
                      <div className={styles.driversItem}>
                        <label htmlFor="vehicle_driver_allowed_experience_license3">
                          № водит. прав:
                        </label>
                        <input
                          id={styles.vehicle_driver_allowed_experience_license3}
                          onInput={handleInput}
                          defaultValue={
                            data.vehicle_driver_allowed_experience_license3
                          }
                          type="text"
                          placeholder="00 00 000000"
                          {...register(
                            "vehicle_driver_allowed_experience_license3",
                            {
                              required: isDriverInputThirdFilled
                                ? "Поле обязательно к заполнению"
                                : null,
                              pattern: {
                                value: /^[a-zA-Z0-9][a-zA-Z0-9]*$/,
                                message: "Введите на латинице",
                              },
                              validate: validateUniqueDriverLicenses,
                            }
                          )}
                          readOnly={!isDriverInputThirdFilled}
                        />
                        <div>
                          {errors?.vehicle_driver_allowed_experience_license3 && (
                            <p
                              style={{
                                color: "red",
                                marginTop: "10px",
                                marginBottom: "10px",
                              }}
                            >
                              {errors
                                ?.vehicle_driver_allowed_experience_license3
                                ?.message || "Ошибка"}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div>
                    <div className={styles.numDriver}>
                      <h1>Водитель № 4</h1>
                    </div>
                    <div className={styles.driversBox}>
                      <div className={styles.driversItem}>
                        <label htmlFor="vehicle_driver_allowed_fio4">
                          ФИО:
                        </label>
                        <input
                          id={styles.vehicle_driver_allowed_fio4}
                          type="text"
                          defaultValue={data.vehicle_driver_allowed_fio4}
                          {...register("vehicle_driver_allowed_fio4", {
                            pattern: {
                              value: /^[\WА-Яа-я]+$/i,
                              message:
                                "Введите только на кириллице! Символы и цифры нельзя!",
                            },
                            minLength: {
                              value: 3,
                              message: "Минимум 3 букв",
                            },
                          })}
                          onChange={(e) => {
                            if (e.target.value.trim() !== "") {
                              setIsDriverInputFourthFilled(true);
                            } else {
                              setIsDriverInputFourthFilled(false);
                              setValue("vehicle_driver_allowed_fio4", null);
                              setValue("vehicle_driver_allowed_dob4", null);
                              setValue(
                                "vehicle_driver_allowed_experience_year4",
                                null
                              );
                              setValue(
                                "vehicle_driver_allowed_experience_license4",
                                null
                              );
                            }
                          }}
                        />

                        <div>
                          {errors?.vehicle_driver_allowed_fio4 && (
                            <p
                              style={{
                                color: "red",
                                marginTop: "10px",
                                marginBottom: "10px",
                              }}
                            >
                              {errors?.vehicle_driver_allowed_fio4?.message ||
                                "Ошибка"}
                            </p>
                          )}
                        </div>
                      </div>
                      <div className={styles.driversItem}>
                        <label htmlFor="vehicle_driver_allowed_dob4">
                          Дата рождения:
                        </label>
                        <input
                          id={styles.vehicle_driver_allowed_dob4}
                          defaultValue={data.vehicle_driver_allowed_dob4}
                          type="date"
                          {...register("vehicle_driver_allowed_dob4", {
                            required: isDriverInputFourthFilled
                              ? "Поле обязательно к заполнению"
                              : null,
                          })}
                          readOnly={!isDriverInputFourthFilled}
                        />
                        <div>
                          {errors?.vehicle_driver_allowed_dob4 && (
                            <p
                              style={{
                                color: "red",
                                marginTop: "10px",
                                marginBottom: "10px",
                              }}
                            >
                              {errors?.vehicle_driver_allowed_dob4?.message ||
                                "Ошибка"}
                            </p>
                          )}
                        </div>
                      </div>
                      <div className={styles.driversItem}>
                        <label htmlFor="vehicle_driver_allowed_experience_year4">
                          Стаж:
                        </label>
                        <input
                          id={styles.vehicle_driver_allowed_experience_year4}
                          defaultValue={
                            data.vehicle_driver_allowed_experience_year4
                          }
                          type="date"
                          {...register(
                            "vehicle_driver_allowed_experience_year4",
                            {
                              required: isDriverInputFourthFilled
                                ? "Поле обязательно к заполнению"
                                : null,
                            }
                          )}
                          readOnly={!isDriverInputFourthFilled}
                        />
                        <div>
                          {errors?.vehicle_driver_allowed_experience_year4 && (
                            <p
                              style={{
                                color: "red",
                                marginTop: "10px",
                                marginBottom: "10px",
                              }}
                            >
                              {errors?.vehicle_driver_allowed_experience_year4
                                ?.message || "Ошибка"}
                            </p>
                          )}
                        </div>
                      </div>
                      <div className={styles.driversItem}>
                        <label htmlFor="vehicle_driver_allowed_experience_license4">
                          № водит. прав:
                        </label>
                        <input
                          id={styles.vehicle_driver_allowed_experience_license4}
                          onInput={handleInput}
                          defaultValue={
                            data.vehicle_driver_allowed_experience_license4
                          }
                          type="text"
                          placeholder="00 00 000000"
                          {...register(
                            "vehicle_driver_allowed_experience_license4",
                            {
                              required: isDriverInputFourthFilled
                                ? "Поле обязательно к заполнению"
                                : null,
                              pattern: {
                                value: /^[a-zA-Z0-9][a-zA-Z0-9]*$/,
                                message: "Введите на латинице",
                              },
                              validate: validateUniqueDriverLicenses,
                            }
                          )}
                          readOnly={!isDriverInputFourthFilled}
                        />
                        <div>
                          {errors?.vehicle_driver_allowed_experience_license4 && (
                            <p
                              style={{
                                color: "red",
                                marginTop: "10px",
                                marginBottom: "10px",
                              }}
                            >
                              {errors
                                ?.vehicle_driver_allowed_experience_license4
                                ?.message || "Ошибка"}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.radioBlock}>
                  <div className={styles.radioBox}>
                    <h1>Наличие карты техосмотра</h1>
                    <div className={styles.radioItem}>
                      <div>
                        <input
                          {...register("is_diagnostic_card_exist", {
                            required: "Поле обязательно к заполнению",
                          })}
                          id={"yes-diagnostic"}
                          type="radio"
                          value={true}
                          checked={data.is_diagnostic_card_exist === true}
                        />
                        <label htmlFor={"yes-diagnostic"}>Есть</label>
                      </div>
                      <div>
                        <input
                          {...register("is_diagnostic_card_exist", {
                            required: "Поле обязательно к заполнению",
                          })}
                          id={"no-diagnostic"}
                          type="radio"
                          value={false}
                          checked={data.is_diagnostic_card_exist === false}
                        />
                        <label htmlFor={"no-diagnostic"}>Нет</label>
                      </div>
                    </div>
                    <div>
                      {errors?.is_diagnostic_card_exist && (
                        <p
                          style={{
                            color: "red",
                            marginTop: "10px",
                            marginBottom: "10px",
                          }}
                        >
                          {errors?.is_diagnostic_card_exist?.message ||
                            "Ошибка"}
                        </p>
                      )}
                    </div>
                  </div>
                  {/* <div className={styles.radioBox}>
                    <h1>Автомобиль зарегистрирован в Кыргызстане?</h1>
                    <div className={styles.radioItem}>
                      <div>
                        <input
                          {...register("is_registration_in_kyrgyzstan", {
                            required: "Поле обязательно к заполнению",
                          })}
                          id={"yes-kyrgyzstan"}
                          type="radio"
                          value={1}
                          checked={data.is_registration_in_kyrgyzstan === true}
                        />
                        <label htmlFor={"yes-kyrgyzstan"}>Да</label>
                      </div>
                      <div>
                        <input
                          {...register("is_registration_in_kyrgyzstan", {
                            required: "Поле обязательно к заполнению",
                          })}
                          id={"no-kyrgyzstan"}
                          type="radio"
                          value={0}
                          checked={data.is_registration_in_kyrgyzstan === false}
                        />
                        <label htmlFor={"no-kyrgyzstan"}>Нет</label>
                      </div>
                    </div>
                    <div>
                      {errors?.is_registration_in_kyrgyzstan && (
                        <p
                          style={{
                            color: "red",
                            marginTop: "10px",
                            marginBottom: "10px",
                          }}
                        >
                          {errors?.is_registration_in_kyrgyzstan?.message ||
                            "Ошибка"}
                        </p>
                      )}
                    </div>
                  </div> */}
                  <div className={styles.radioBox}>
                    <h1>Автомобиль зарегистрирован в Кыргызстане?</h1>
                    <div className={styles.radioItem}>
                      <div>
                        <input
                          {...register("is_registration_in_kyrgyzstan")}
                          id={"yes-kyrgyzstan"}
                          type="radio"
                          value={true}
                          checked={data.is_registration_in_kyrgyzstan === true}
                        />
                        <label htmlFor={"yes-kyrgyzstan"}>Да</label>
                      </div>
                      <div>
                        <input
                          {...register("is_registration_in_kyrgyzstan")}
                          id={"no-kyrgyzstan"}
                          type="radio"
                          value={false}
                          checked={data.is_registration_in_kyrgyzstan === false}
                        />
                        <label htmlFor={"no-kyrgyzstan"}>Нет</label>
                      </div>
                    </div>
                    <div>
                      {errors?.is_registration_in_kyrgyzstan && (
                        <p
                          style={{
                            color: "red",
                            marginTop: "10px",
                            marginBottom: "10px",
                          }}
                        >
                          {errors?.is_registration_in_kyrgyzstan?.message ||
                            "Ошибка"}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                <div className={styles.user}>
                  <div className={styles.dataItem}>
                    <label htmlFor="vehicle_doc_sequence">Номер полиса</label>
                    <div
                      style={
                        errors?.vehicle_doc_sequence
                          ? { marginBottom: "10px" }
                          : { marginBottom: "0px" }
                      }
                      className={styles.polisNum}
                    >
                      <p>13</p>
                      <input
                        onInput={(e) => {
                          if (e.target.value.length > e.target.maxLength)
                            e.target.value = e.target.value.slice(
                              0,
                              e.target.maxLength
                            );
                        }}
                        onKeyDown={(event) => checkIfNumber(event)}
                        maxLength={5}
                        type="number"
                        placeholder="000000"
                        // defaultValue={data.vehicle_doc_sequence}
                        {...register("vehicle_doc_sequence", {
                          required: "Поле обязательно к заполнению",
                          minLength: {
                            value: 5,
                            message: "Введите 6 цифр",
                          },
                          maxLength: {
                            value: 5,
                            message: "Неккоректный номер полиса",
                          },
                          pattern: {
                            value: /[0-9]/,
                          },
                        })}
                      />
                    </div>

                    <div>
                      {errors?.vehicle_doc_sequence && (
                        <p
                          style={{
                            color: "red",
                            marginTop: "10px",
                            marginBottom: "10px",
                          }}
                        >
                          {errors?.vehicle_doc_sequence?.message || "Ошибка"}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className={styles.dataItem}>
                    <label htmlFor="credit_agent_username">Логин Агента </label>
                    <input
                      id={styles.credit_agent_username}
                      type="text"
                      placeholder="ХХхХхХХх"
                      {...register("credit_agent_username", {
                        required: "Поле обязательно к заполнению",
                        pattern: {
                          value: /^[a-zA-Z][a-zA-Z0-9-_\.]/,
                          message: "Неправильный логин: введите на латинице",
                        },
                      })}
                    />

                    <div>
                      {errors?.credit_agent_username && (
                        <p style={{ color: "red", marginTop: "10px" }}>
                          {errors?.credit_agent_username?.message || "Ошибка"}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className={styles.dataItem}>
                    <label htmlFor="credit_agent_password">Пароль</label>
                    <input
                      id={styles.credit_agent_password}
                      type="password"
                      placeholder="********"
                      {...register("credit_agent_password", {
                        required: "Поле обязательно к заполнению",
                      })}
                    />

                    <div>
                      {errors?.credit_agent_password && (
                        <p style={{ color: "red", marginTop: "10px" }}>
                          {errors?.credit_agent_password?.message || "Ошибка"}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.btn}>
                {loading ? (
                  <span className="loading"></span>
                ) : (
                  <button type="submit">Сохранить</button>
                )}
              </div>
            </div>
          </form>
        </div>
        {lastResult ? (
          <LastResult
            closeForm={closeForm}
            closeModal={() => setLastResult(false)}
            vehicle_doc_sequence={vehicle_doc_sequence}
            resultSum={resultSum}
          />
        ) : null}
      </div>
    </div>
  );
};

export default DataPolisies;
