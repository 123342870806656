import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import styles from "./Polisies.module.css";
import close from "../image/Закрыть.svg";

import { API_URL } from "../Сonstants";
const TableStyled = styled(Table)({
  "@media (max-width: 850px)": {
    width: 600,
  },
});
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function Polisies({ closeModal, data }) {
  const download = (num) => {
    var getMyHeaders = new Headers();

    getMyHeaders.append("Content-Type", "application/json");

    var getRequestOptions = {
      method: "GET",
      headers: getMyHeaders,
      redirect: "follow",
    };
    fetch(
      `${API_URL}/open_pdf_api?policyNumber=${num}`,
      getRequestOptions
    ).then(async (res) => {
      // FileDownload(await res.blob(), "asd3.pdf")
      const data = await res.blob();
      const url = window.URL.createObjectURL(
        new Blob([data], { type: "application/pdf" })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("target", "_blank");
      document.body.appendChild(link);
      link.click();
      // Clean up and remove the link
      link.parentNode.removeChild(link);
    });
  };
  return (
    <div className={styles.window}>
      <div className="wrapper">
        <div className={styles.windowHead}>
          <div className={styles.windowText}>
            <h1>Выданные полисы</h1>
          </div>
          <img onClick={closeModal} src={close} alt="close" />
        </div>
        <div className={styles.table}>
          <TableStyled aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>№ Полиса</StyledTableCell>
                <StyledTableCell align="right">Ф.И.О</StyledTableCell>
                <StyledTableCell align="right">Сумма кредита</StyledTableCell>
                <StyledTableCell align="right">
                  Дата начала кредита
                </StyledTableCell>
                <StyledTableCell align="right">
                  Дата конца кредита
                </StyledTableCell>
                <StyledTableCell align="right">Агент</StyledTableCell>
                <StyledTableCell align="right">Скачать</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Array.isArray(data) &&
                data.map((item) => (
                  <StyledTableRow key={data.credit_doc_sequence}>
                    <StyledTableCell component="th" scope="row">
                      {item.credit_doc_sequence}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {item.credit_insurant_fio}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {item.credit_sum}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {item.credit_start_date}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {item.credit_end_date}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {item.credit_agent}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      <button
                        onClick={() => {
                          download(item.credit_doc_sequence);
                        }}
                      >
                        Скачать
                      </button>
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
            </TableBody>
          </TableStyled>
        </div>
      </div>
    </div>
  );
}
