import React from "react";
import styles from "./Footer.module.css";
function Footer() {
  return (
    <div className={styles.footer}>
      <h3>2019-{(new Date().getFullYear())} Все права защищены | ЗАО Страховая компания «Здоровье»</h3>
    </div>
  );
}

export default Footer;
