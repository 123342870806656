/* eslint-disable no-sequences */
import React, { useState, useEffect } from "react";
import { useForm, useWatch } from "react-hook-form";
import styles from "./Form.module.css";
import "react-phone-number-input/style.css";
import PhoneNumber from "./PhoneNumber";
import { toast } from "react-toastify";
import { API_URL } from "../Сonstants";
import Polisies from "./Polisies";

function Form() {
  // // startDate
  // const today = new Date();
  // const date = today.setDate(today.getDate());
  // const defaultValue = new Date(date).toISOString().split("T")[0]; // yyyy-mm-dd
  // // endDate
  // const inAYear = new Date();
  // const yearDate = inAYear.setDate(inAYear.getDate() + 365);
  // const defaultValueYear = new Date(yearDate).toISOString().split("T")[0]; // yyyy-mm-dd

  // startDate
  const todayDate = new Date().toISOString().substr(0, 10);
  // endDate
  const yearDate = new Date(
    new Date().getFullYear() + 1,
    new Date().getMonth(),
    new Date().getDate()
  )
    .toISOString()
    .substr(0, 10);
  // useForm
  const {
    register,
    formState: { errors, isValid },
    handleSubmit,
    reset,
    control,
  } = useForm({
    mode: "onChange",
    // defaultValues: {
    //   credit_start_date: defaultValue,
    //   credit_end_date: defaultValueYear,
    // },
  });
  const [modalActive, setModalActive] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const [loading, setLoading] = useState(false);

  const [dataPolisies, setDataPolisies] = useState([]);
  const [result, setResult] = useState(0);
  const credit_sum = useWatch({
    control,
    name: "credit_sum",
  });

  const credit_start_date = useWatch({
    control,
    name: "credit_start_date",
  });
  const credit_end_date = useWatch({
    control,
    name: "credit_end_date",
  });
  const credit_agent_username = useWatch({
    control,
    name: "credit_agent_username",
  });
  const credit_agent_password = useWatch({
    control,
    name: "credit_agent_password",
  });

  // GET
  var getMyHeaders = new Headers();

  getMyHeaders.append("Content-Type", "application/json");

  var getRequestOptions = {
    method: "GET",
    headers: getMyHeaders,
    redirect: "follow",
  };

  useEffect(() => {
    if (
      credit_sum &&
      // credit_start_date &&
      // credit_end_date &&
      credit_end_date > credit_start_date
    ) {
      fetch(
        `${API_URL}/get_price?credit_sum=${credit_sum}&credit_start_date=${credit_start_date}&credit_end_date=${credit_end_date}`,
        getRequestOptions
      )
        .then((response) => response.json())
        .then((data) => setResult(data.final_sum));
    }
  }, [credit_sum, credit_start_date, credit_end_date]);

  // Submit
  const onSubmit = (data) => {
    setDisabled(true);
    setLoading(true);
    var myHeaders = new Headers();

    myHeaders.append("Content-Type", "application/pdf");
    data.credit_language = parseInt(data.credit_language);
    data.credit_sum = parseInt(data.credit_sum);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(data),
      redirect: "follow",
    };

    fetch(`${API_URL}/credit-create/`, requestOptions)
      .then(async (res) => {
        if (res?.status >= 400) {
          setLoading(false);
          setDisabled(false);
          return toast.error("Неправильный логин или пароль");
        }
        toast.success("Форма успешно отправлена");
        setLoading(false);
        setDisabled(false);
        reset();
        // FileDownload(await res.blob(), "asd3.pdf")
        const data = await res.blob();
        const url = window.URL.createObjectURL(
          new Blob([data], { type: "application/pdf" })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("target", "_blank");
        document.body.appendChild(link);
        link.click();
        // Clean up and remove the link
        link.parentNode.removeChild(link);
      })
      .catch((err) => {
        setLoading(false);
        setDisabled(false);
        toast.error("Форма не отправлена");
      })
      .finally(() => {
        setLoading(false);
        setDisabled(false);
      });
  };
  // Выданные полисы
  const onSubmitPolisies = () => {
    setLoading(true);
    setDisabled(true);
    var myHeaders = new Headers();

    myHeaders.append("Content-Type", "application/pdf");

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify({
        credit_agent_username: credit_agent_username,
        credit_agent_password: credit_agent_password,
      }),
      redirect: "follow",
    };

    fetch(`${API_URL}/list_credit_insurance_api/`, requestOptions)
      .then((res) => {
        if (res?.status >= 400) {
          setLoading(false);
          setDisabled(false);
          return toast.error("Неправильный логин или пароль");
        } else {
          setLoading(false);
          setDisabled(false);
          setModalActive(!modalActive);
        }
        return res.json();
      })
      .then((data) => {
        setDataPolisies(data);
        setLoading(false);
        setDisabled(false);
      })
      .catch((err) => {
        setLoading(false);
        setDisabled(false);

        toast.error("Ошибка с сервером");
      })
      .finally(() => {
        setLoading(false);
        setDisabled(false);
      });
  };

  // firefox
  const checkIfNumber = (event) => {
    const regex = new RegExp(
      /(^\d*$)|(Backspace|Tab|Delete|ArrowLeft|ArrowRight)/
    );
    return !event.key.match(regex) && event.preventDefault();
  };

  return (
    <div className={styles.form}>
      <div className="container">
        <h1>ОФОРМИТЬ ПОЛИС НС</h1>
        <form action="#" onSubmit={handleSubmit(onSubmit)}>
          <div className={styles.formBlocks}>
            <div className={styles.block}>
              <div className={styles.box}>
                <label htmlFor="credit_insurant_fio">
                  *Фамилия застрахованного
                </label>
                <input
                  id={styles.credit_insurant_fio}
                  type="text"
                  placeholder="Ф.И.О"
                  {...register("credit_insurant_fio", {
                    required: "Поле обязательно к заполнению",
                    pattern: {
                      value: /^[\WА-Яа-я]+$/i,
                      message:
                        "Введите только на кириллице! Символы и цифры нельзя!",
                    },
                    minLength: {
                      value: 3,
                      message: "Минимум 3 букв",
                    },
                  })}
                />

                <div>
                  {errors?.credit_insurant_fio && (
                    <p style={{ color: "red", marginTop: "10px" }}>
                      {errors?.credit_insurant_fio?.message || "Ошибка"}
                    </p>
                  )}
                </div>
              </div>
              <div className={styles.box}>
                <label htmlFor="credit_insurant_inn">
                  *ИНН застрахованного{" "}
                </label>
                <input
                  onInput={(e) => {
                    if (e.target.value.length > e.target.maxLength)
                      e.target.value = e.target.value.slice(
                        0,
                        e.target.maxLength
                      );
                  }}
                  onKeyDown={(event) => checkIfNumber(event)}
                  maxLength={14}
                  id={styles.credit_insurant_inn}
                  type="number"
                  placeholder="200000000000000"
                  {...register("credit_insurant_inn", {
                    required: "Поле обязательно к заполнению",
                    minLength: {
                      value: 14,
                      message: "Введите 14 цифр",
                    },
                    maxLength: {
                      value: 14,
                      message: "Неккоректный ИНН",
                    },
                  })}
                />

                <div>
                  {errors?.credit_insurant_inn && (
                    <p style={{ color: "red", marginTop: "10px" }}>
                      {errors?.credit_insurant_inn?.message || "Ошибка"}
                    </p>
                  )}
                </div>
              </div>
            </div>
            <div className={styles.block}>
              <div className={styles.box}>
                <label htmlFor="credit_insurant_address">
                  *Адрес застрахованного{" "}
                </label>
                <input
                  id={styles.credit_insurant_address}
                  type="text"
                  placeholder="г. Название, ул. Название, № дома"
                  {...register("credit_insurant_address", {
                    required: "Поле обязательно к заполнению",
                  })}
                />

                <div>
                  {errors?.credit_insurant_address && (
                    <p style={{ color: "red", marginTop: "10px" }}>
                      {errors?.credit_insurant_address?.message || "Ошибка"}
                    </p>
                  )}
                </div>
              </div>

              <div className={styles.box}>
                <PhoneNumber
                  register={register}
                  errors={errors}
                  control={control}
                />
              </div>
            </div>
            <div className={styles.block}>
              <div className={styles.box}>
                <label htmlFor="credit_agreement_number">
                  *Номер кредитного договора{" "}
                </label>
                <input
                  id={styles.credit_agreement_number}
                  type="text"
                  placeholder="000000000000000"
                  onInput={(e) => {
                    if (e.target.value.length > e.target.maxLength)
                      e.target.value = e.target.value.slice(
                        0,
                        e.target.maxLength
                      );
                  }}
                  maxLength={16}
                  {...register("credit_agreement_number", {
                    required: "Поле обязательно к заполнению",
                    maxLength: {
                      value: 16,
                      message: "Введите 16 символов",
                    },
                  })}
                />

                <div>
                  {errors?.credit_agreement_number && (
                    <p style={{ color: "red", marginTop: "10px" }}>
                      {errors?.credit_agreement_number?.message || "Ошибка"}
                    </p>
                  )}
                </div>
              </div>
              <div className={styles.box}>
                <label htmlFor="credit_sum">*Сумма кредита (сом) </label>

                <input
                  onInput={(e) => {
                    if (e.target.value <= 0)
                      e.target.value = e.target.value.slice(0, e.target.value);
                  }}
                  onKeyDown={(event) => checkIfNumber(event)}
                  id={styles.credit_sum}
                  type="number"
                  placeholder="000000"
                  {...register("credit_sum", {
                    required: "Поле обязательно к заполнению",
                    pattern: {
                      value: /[1-9]/,
                      message: "Введите только цифры!",
                    },
                    minLength: {
                      value: 1,
                      message: "Минимум 1 цифра",
                    },
                  })}
                />

                <div>
                  {errors?.credit_sum && (
                    <p style={{ color: "red", marginTop: "10px" }}>
                      {errors?.credit_sum?.message || "Ошибка"}
                    </p>
                  )}
                </div>
              </div>
            </div>
            <div className={styles.blockLast}>
              <div className={styles.formBox_1}>
                <div className={styles.formDate}>
                  <div className={styles.box2}>
                    <label htmlFor="credit_start_date">
                      *Дата начала кредита
                    </label>
                    <input
                      id={styles.credit_start_date}
                      type="date"
                      defaultValue={todayDate}
                      {...register("credit_start_date", {
                        required: "Поле обязательно к заполнению",
                      })}
                    />

                    <div>
                      {errors?.credit_start_date && (
                        <p style={{ color: "red", marginTop: "10px" }}>
                          {errors?.credit_start_date?.message || "Ошибка"}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className={styles.box2}>
                    <label htmlFor="credit_end_date">
                      *Дата окончания кредита{" "}
                    </label>

                    <input
                      id={styles.credit_end_date}
                      type="date"
                      defaultValue={yearDate}
                      {...register("credit_end_date", {
                        required: "Поле обязательно к заполнению",
                        message:
                          "Нельзя делать дату окончание меньше даты начало",
                      })}
                    />

                    <div>
                      {credit_start_date >= credit_end_date ? (
                        <p style={{ color: "red", marginTop: "10px" }}>
                          {
                            "Нельзя делать дату окончание меньше либо равным даты начало"
                          }
                        </p>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
                <div className={styles.box2}>
                  <div className={styles.lang}>
                    <label htmlFor="language1">*Язык полиса</label>
                    <div className={styles.radioBlock} id="language1">
                      <div className={styles.radio}>
                        <input
                          {...register("credit_language", {
                            required: "Поле обязательно к заполнению",
                          })}
                          className={styles.language}
                          type="radio"
                          id="rus"
                          checked
                          value={1}
                          // value="Russian"
                        />
                        <label htmlFor="rus">Русский</label>
                      </div>
                      <div className={styles.radio}>
                        <input
                          {...register("credit_language", {
                            required: "Поле обязательно к заполнению",
                          })}
                          type="radio"
                          id="kgz"
                          value={2}
                          className={styles.language}

                          // value="Russian"
                        />
                        <label htmlFor="kgz">Кыргызский</label>
                      </div>
                    </div>
                    <div>
                      {errors?.credit_language && (
                        <p style={{ color: "red", marginTop: "10px" }}>
                          {errors?.credit_language?.message || "Ошибка"}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.formBox_2}>
                <div className={styles.formUserName}>
                  <div className={styles.box2}>
                    <label htmlFor="credit_agent_username">
                      *Логин Агента{" "}
                    </label>
                    <input
                      id={styles.credit_agent_username}
                      type="text"
                      placeholder="ХХхХхХХх"
                      {...register("credit_agent_username", {
                        required: "Поле обязательно к заполнению",
                        pattern: {
                          value: /^[a-zA-Z][a-zA-Z0-9-_\.]/,
                          message: "Неправильный логин: введите на латинице",
                        },
                      })}
                    />

                    <div>
                      {errors?.credit_agent_username && (
                        <p style={{ color: "red", marginTop: "10px" }}>
                          {errors?.credit_agent_username?.message || "Ошибка"}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className={styles.box2}>
                    <label htmlFor="credit_agent_password">*Пароль</label>
                    <input
                      id={styles.credit_agent_password}
                      type="password"
                      placeholder="********"
                      {...register("credit_agent_password", {
                        required: "Поле обязательно к заполнению",
                      })}
                    />

                    <div>
                      {errors?.credit_agent_password && (
                        <p style={{ color: "red", marginTop: "10px" }}>
                          {errors?.credit_agent_password?.message || "Ошибка"}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                <div className={styles.result}>
                  <label htmlFor="result">Стоимость полиса</label>
                  <div className={styles.resBox}>
                    <h2 id="result">{result}</h2> <h5> сом (в. т. НсП 3%)</h5>
                  </div>
                </div>{" "}
              </div>
            </div>
          </div>
          <div className={styles.formButton}>
            <button
              name="postForm"
              type="submit"
              className={styles.formButton1}
              disabled={!isValid || disabled}
              style={
                disabled || !isValid
                  ? { opacity: "0.5", cursor: "default" }
                  : { opacity: "1", cursor: "pointer" }
              }
            >
              Подтвердить оплату
            </button>

            <button
              // onSubmit={handleSubmit(onSubmitPolisies)}
              onClick={() => {
                onSubmitPolisies();
              }}
              type="button"
              name="polis"
              style={
                disabled || !credit_agent_username || !credit_agent_password
                  ? { opacity: "0.5", cursor: "default" }
                  : { opacity: "1", cursor: "pointer" }
              }
              className={styles.formButton2}
              disabled={!credit_agent_username || !credit_agent_password}
            >
              выданные полисы
            </button>
          </div>
          {loading && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "30px",
              }}
            >
              <span className="loading"></span>
            </div>
          )}
        </form>
      </div>
      {modalActive ? (
        <Polisies
          data={dataPolisies}
          closeModal={() => setModalActive(false)}
        />
      ) : null}
    </div>
  );
}

export default Form;
