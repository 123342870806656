import React, { useState } from "react";
import { useForm } from "react-hook-form";
import styles from "./Modal.module.css";
import close from "../image/Закрыть.svg";
import { toast } from "react-toastify";

import { API_URL } from "../Сonstants";
export default function Modal({
  fio,
  date_to,
  date_from,
  balance,
  policyNumber,
  closeModal,
}) {
  const {
    register,
    formState: { errors, isValid },
    handleSubmit,
    reset,
  } = useForm({
    mode: "onChange",
    defaultValues: {
      policyNumber,
    },
  });
  const [disabled, setDisabled] = useState(false);
  const [loading, setLoading] = useState(false);

  // const notify = () => {
  //   toast.error("Некорректные данные", {
  //     position: "top-right",
  //     autoClose: 5000,
  //     hideProgressBar: false,
  //     closeOnClick: true,
  //     pauseOnHover: true,
  //     draggable: true,
  //     progress: undefined,
  //     theme: "colored",
  //   });
  // };
  const onSubmit = (data) => {
    setDisabled(true);
    setLoading(true);
    data.policyNumber = policyNumber;
    var myHeaders = new Headers();

    myHeaders.append("Content-Type", "application/json");
    data.reservSum = parseInt(data.reservSum);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(data),
    };

    fetch(`${API_URL}/dms_balance_reduce_api`, requestOptions)
      .then(async (res) => {
        if (res?.status === 401) {
          setLoading(false);
          setDisabled(false);
          return toast.error("Неправильный логин или пароль");
        }
        if (res?.status >= 400) {
          setLoading(false);
          setDisabled(false);
          return toast.error("Недостаточно стредств");
        }
        toast.success("Форма успешно отправлена");
        setLoading(false);
        setDisabled(false);
        reset();
        // FileDownload(await res.blob(), "asd3.pdf")
        const data = await res.blob();
        const url = window.URL.createObjectURL(
          new Blob([data], { type: "application/pdf" })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("target", "_blank");
        document.body.appendChild(link);
        link.click();
        // Clean up and remove the link
        link.parentNode.removeChild(link);
      })
      .catch((err) => {
        setLoading(false);
        setDisabled(false);
        toast.error("Форма не отправлена");
      })
      .finally(() => {
        setLoading(false);
        setDisabled(false);
      });
  };
  // firefox
  const checkIfNumber = (event) => {
    const regex = new RegExp(
      /(^\d*$)|(Backspace|Tab|Delete|ArrowLeft|ArrowRight)/
    );
    return !event.key.match(regex) && event.preventDefault();
  };
  return (
    <div className={styles.window}>
      <div className="wrapper">
        <div className={styles.wrapperWindow}>
          <div className={styles.windowHead}>
            <div className="windowText">
              <h2>
                <span>Полис: </span>
                ДМС Ф {policyNumber}
              </h2>

              <h2>
                <span> Застрахованный: </span>
                {fio}
              </h2>

              <h2>
                <span> Срок действия: </span>
                {date_from} - {date_to}
              </h2>

              <h2>
                <span>Доступный баланс: </span>
                {balance}
              </h2>
            </div>

            <img onClick={closeModal} src={close} alt="close" />
          </div>
          <form action="#" onSubmit={handleSubmit(onSubmit)}>
            <div className={styles.windowForm}>
              <div
                className={styles.formBox}
                style={
                  errors?.clinicPassword || errors?.credit_agent_username
                    ? { marginBottom: "0px" }
                    : { marginBottom: "10px" }
                }
              >
                <div className={styles.box}>
                  <label htmlFor="reservSum">Сумма оплаты</label>
                  <input
                    onInput={(e) => {
                      if (e.target.value.charAt(0) === 0) {
                        e.target.value = e.target.value.slice(
                          0,
                          e.target.value
                        );
                      }
                    }}
                    onKeyDown={(event) => checkIfNumber(event)}
                    id={styles.reservSum}
                    name="reservSum"
                    type="number"
                    placeholder="000000"
                    {...register("reservSum", {
                      required: "Поле обязательно к заполнению",
                      pattern: {
                        value: /[1-9]/,
                        message: "Введите только цифры!",
                      },
                      min: {
                        value: 1,
                        message: "Минимум 1 цифра",
                      },
                    })}
                  />

                  <div>
                    {errors?.reservSum && (
                      <p style={{ color: "red", marginTop: "10px" }}>
                        {errors?.reservSum?.message || "Ошибка"}
                      </p>
                    )}
                  </div>
                </div>
                <div className={styles.box}>
                  <label htmlFor="comment">Произведенное лечение</label>
                  <input
                    name="comment"
                    id={styles.comment}
                    type="text"
                    placeholder="ххххххххххх"
                    {...register("comment", {
                      required: "Поле обязательно к заполнению",
                      pattern: {
                        value: /^[\WА-Яа-я0-9]+$/i,
                        message: "Введите только на кириллице!",
                      },
                      minLength: {
                        value: 3,
                        message: "Минимум 3 букв",
                      },
                    })}
                  />

                  <div>
                    {errors?.comment && (
                      <p style={{ color: "red", marginTop: "10px" }}>
                        {errors?.comment?.message || "Ошибка"}
                      </p>
                    )}
                  </div>
                </div>
                <div className={styles.box2}>
                  <label htmlFor="clinicNumber">Логин клиники</label>
                  <input
                    // value={login}
                    // onChange={loginHandler}
                    id={styles.clinicNumber}
                    name="clinicNumber"
                    type="text"
                    placeholder="ххххххххххх"
                    {...register("clinicNumber", {
                      required: "Поле обязательно к заполнению",
                      // pattern: {
                      //   value: /^[a-z][a-z0-9-]*$/i,
                      //   message: "Неправильный логин: введите на латинице",
                      // },
                    })}
                  />

                  <div>
                    {errors?.clinicNumber && (
                      <p style={{ color: "red", marginTop: "10px" }}>
                        {errors?.clinicNumber?.message || "Ошибка"}
                      </p>
                    )}
                  </div>
                </div>
                <div className={styles.box2}>
                  <label htmlFor="clinicPassword">Пароль </label>
                  <input
                    //  onChange={passwordHandler}
                    //  value={password}
                    id={styles.clinicPassword}
                    name="clinicPassword"
                    type="password"
                    placeholder="********"
                    {...register("clinicPassword", {
                      required: "Поле обязательно к заполнению",
                      // pattern: {
                      //   value: /^[a-z][a-z0-9-]*$/i,
                      //   message: "Неправильный пароль",
                      // },
                    })}
                  />

                  <div>
                    {errors?.clinicPassword && (
                      <p style={{ color: "red", marginTop: "10px" }}>
                        {errors?.clinicPassword?.message || "Ошибка"}
                      </p>
                    )}
                  </div>
                </div>
                <div className={styles.box2}>
                  <input type="hidden" {...register("policyNumber", {})} />
                </div>
              </div>
              {loading ? (
                <span className="loading"></span>
              ) : (
                <button
                  type="submit"
                  disabled={!isValid || disabled}
                  style={
                    disabled || !isValid
                      ? { opacity: "0.5", cursor: "default" }
                      : { opacity: "1", cursor: "pointer" }
                  }
                >
                  Произве вычет
                </button>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
