import React, { useState, useEffect } from "react";
import styles from "./PersonForm.module.css";
import { useForm, useWatch } from "react-hook-form";
import "react-phone-number-input/style.css";
// import PhoneNumber from "./PhoneNumber";
import { toast } from "react-toastify";
import { API_URL } from "../../../Сonstants";
import { useFormContext } from "react-hook-form";
const PersonForm = () => {
  // useForm
  const {
    register,
    formState: { errors },
  } = useFormContext({
    mode: "onChange",
  });
  // firefox
  const checkIfNumber = (event) => {
    const regex = new RegExp(
      /(^\d*$)|(Backspace|Tab|Delete|ArrowLeft|ArrowRight)/
    );
    return !event.key.match(regex) && event.preventDefault();
  };

  return (
    <div className={styles.form}>
      <div className="container">
        <div className={styles.person}>
          <div className={styles.headText}>
            <h1>Страхователь</h1>
          </div>

          <div className={styles.formBlock}>
            <div className={styles.inputBlock}>
              <div className={styles.box}>
                <label htmlFor="vehicle_insurant_fio">
                  Фамилия Имя Отчество
                </label>
                <input
                  id={styles.vehicle_insurant_fio}
                  type="text"
                  placeholder="Ф.И.О"
                  {...register("vehicle_insurant_fio", {
                    required: "Поле обязательно к заполнению",
                    pattern: {
                      value: /^[\WА-Яа-я]+$/i,
                      message:
                        "Введите только на кириллице! Символы и цифры нельзя!",
                    },
                    minLength: {
                      value: 3,
                      message: "Минимум 3 букв",
                    },
                  })}
                />

                <div>
                  {errors?.vehicle_insurant_fio && (
                    <p
                      style={{
                        color: "red",
                        marginTop: "10px",
                        marginBottom: "10px",
                      }}
                    >
                      {errors?.vehicle_insurant_fio?.message || "Ошибка"}
                    </p>
                  )}
                </div>
              </div>
              <div className={styles.box}>
                <label htmlFor="vehicle_insurant_pin">ИНН</label>
                <input
                  onInput={(e) => {
                    if (e.target.value.length > e.target.maxLength)
                      e.target.value = e.target.value.slice(
                        0,
                        e.target.maxLength
                      );
                  }}
                  onKeyDown={(event) => checkIfNumber(event)}
                  maxLength={14}
                  id={styles.vehicle_insurant_pin}
                  type="number"
                  placeholder="200000000000000"
                  {...register("vehicle_insurant_pin", {
                    required: "Поле обязательно к заполнению",
                    minLength: {
                      value: 14,
                      message: "Введите 14 цифр",
                    },
                    maxLength: {
                      value: 14,
                      message: "Неккоректный ИНН",
                    },
                  })}
                />

                <div>
                  {errors?.vehicle_insurant_pin && (
                    <p
                      style={{
                        color: "red",
                        marginTop: "10px",
                        marginBottom: "10px",
                      }}
                    >
                      {errors?.vehicle_insurant_pin?.message || "Ошибка"}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PersonForm;
