import React, { useState } from "react";
import { useFormContext } from "react-hook-form";
import styles from "./DriversForm.module.css";
export default function DriversForm({ handleInput }) {
  // useForm
  const {
    register,
    formState: { errors },
    setValue,
    getValues,
  } = useFormContext({
    mode: "onChange",
  });

  const [isDriverInputSecondFilled, setIsDriverInputSecondFilled] =
    useState(false);
  const [isDriverInputThirdFilled, setIsDriverInputThirdFilled] =
    useState(false);
  const [isDriverInputFourthFilled, setIsDriverInputFourthFilled] =
    useState(false);

  const validateUniqueDriverLicenses = () => {
    const driverLicenses = [
      getValues("vehicle_driver_allowed_experience_license1") || "",
      getValues("vehicle_driver_allowed_experience_license2") || "",
      getValues("vehicle_driver_allowed_experience_license3") || "",
      getValues("vehicle_driver_allowed_experience_license4") || "",
    ];

    // Проверяем, что минимум два номера заполнены перед выполнением проверки уникальности
    const filledLicenses = driverLicenses.filter(
      (license) => license.trim() !== ""
    );
    if (filledLicenses.length >= 2) {
      const uniqueLicenses = new Set(
        filledLicenses.map((license) => license.trim())
      );

      return (
        uniqueLicenses.size === filledLicenses.length ||
        "Номера водительских прав не должны повторяться"
      );
    }

    // Если не заполнено два номера, то считаем валидацию успешной
    return true;
  };

  return (
    <div className={styles.driversForm}>
      <div className={styles.drivers}>
        <div className={styles.headText}>
          <h1>Водители допущенные к управлению:</h1>
        </div>
        <div className={styles.content}>
          <div className={styles.labelInput}>
            <div>
              <h1>ФИО</h1>
            </div>
            <div>
              <h1>Дата рождения</h1>
            </div>
            <div>
              <h1>Стаж</h1>
            </div>
            <div>
              <h1>№ водит. прав</h1>
            </div>
          </div>

          <div className={styles.input}>
            <div className={styles.driverInput}>
              <div className={styles.inputBlock}>
                <div className={styles.box}>
                  <h1>ФИО:</h1>

                  <div className={styles.item + " " + styles.firstForm}>
                    <input
                      id={styles.vehicle_driver_allowed_fio1}
                      type="text"
                      placeholder="Фамилия Имя Отчество"
                      {...register("vehicle_driver_allowed_fio1", {
                        required: "Поле обязательно к заполнению",
                        pattern: {
                          value: /^[\WА-Яа-я]+$/i,
                          message:
                            "Введите только на кириллице! Символы и цифры нельзя!",
                        },
                        minLength: {
                          value: 3,
                          message: "Минимум 3 букв",
                        },
                      })}
                    />
                    <div>
                      {errors?.vehicle_driver_allowed_fio1 && (
                        <p
                          style={{
                            color: "red",
                            marginTop: "10px",
                            marginBottom: "10px",
                          }}
                        >
                          {errors?.vehicle_driver_allowed_fio1?.message ||
                            "Ошибка"}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                <div className={styles.box}>
                  <h1>Дата рождения:</h1>
                  <div className={styles.item + " " + styles.secondForm}>
                    <input
                      id={styles.vehicle_driver_allowed_dob1}
                      type="text"
                      onFocus={(e) => (e.target.type = "date")}
                      onBlur={(e) => (e.target.type = "text")}
                      placeholder="00.00.0000"
                      {...register("vehicle_driver_allowed_dob1", {
                        required: "Поле обязательно к заполнению",
                      })}
                    />
                    <div>
                      {errors?.vehicle_driver_allowed_dob1 && (
                        <p
                          style={{
                            color: "red",
                            marginTop: "10px",
                            marginBottom: "10px",
                          }}
                        >
                          {errors?.vehicle_driver_allowed_dob1?.message ||
                            "Ошибка"}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                <div className={styles.box}>
                  <h1>Стаж:</h1>
                  <div className={styles.item + " " + styles.secondForm}>
                    <input
                      id={styles.vehicle_driver_allowed_experience_year1}
                      type="text"
                      onFocus={(e) => (e.target.type = "date")}
                      onBlur={(e) => (e.target.type = "text")}
                      placeholder="00.00.0000"
                      {...register("vehicle_driver_allowed_experience_year1", {
                        required: "Поле обязательно к заполнению",
                      })}
                    />
                    <div>
                      {errors?.vehicle_driver_allowed_experience_year1 && (
                        <p
                          style={{
                            color: "red",
                            marginTop: "10px",
                            marginBottom: "10px",
                          }}
                        >
                          {errors?.vehicle_driver_allowed_experience_year1
                            ?.message || "Ошибка"}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                <div className={styles.box}>
                  <h1>№ водит. прав:</h1>
                  <div className={styles.item + " " + styles.fourthForm}>
                    <input
                      type="text"
                      placeholder="00 00 000000"
                      onInput={handleInput}
                      {...register(
                        "vehicle_driver_allowed_experience_license1",
                        {
                          required: "Поле обязательно к заполнению",
                          pattern: {
                            value: /^[a-zA-Z0-9][a-zA-Z0-9]*$/,
                            message: "Введите на латинице",
                          },
                          validate: validateUniqueDriverLicenses,
                        }
                      )}
                    />
                    <div>
                      {errors?.vehicle_driver_allowed_experience_license1 && (
                        <p
                          style={{
                            color: "red",
                            marginTop: "10px",
                            marginBottom: "10px",
                          }}
                        >
                          {errors?.vehicle_driver_allowed_experience_license1
                            ?.message || "Ошибка"}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.driverInputSecond}>
              <div className={styles.inputBlock}>
                <div className={styles.box}>
                  <h1>ФИО:</h1>
                  <div className={styles.item + " " + styles.firstForm}>
                    <input
                      id={styles.vehicle_driver_allowed_fio2}
                      type="text"
                      placeholder="Фамилия Имя Отчество"
                      {...register("vehicle_driver_allowed_fio2", {
                        pattern: {
                          value: /^[\WА-Яа-я]+$/i,
                          message:
                            "Введите только на кириллице! Символы и цифры нельзя!",
                        },
                        minLength: {
                          value: 3,
                          message: "Минимум 3 букв",
                        },
                      })}
                      onChange={(e) => {
                        if (e.target.value.trim() !== "") {
                          setIsDriverInputSecondFilled(true);
                        } else {
                          setIsDriverInputSecondFilled(false);
                          setValue("vehicle_driver_allowed_fio2", null);
                          setValue("vehicle_driver_allowed_dob2", null);
                          setValue(
                            "vehicle_driver_allowed_experience_year2",
                            null
                          );
                          setValue(
                            "vehicle_driver_allowed_experience_license2",
                            null
                          );
                        }
                      }}
                    />
                    <div>
                      {errors?.vehicle_driver_allowed_fio2 && (
                        <p
                          style={{
                            color: "red",
                            marginTop: "10px",
                            marginBottom: "10px",
                          }}
                        >
                          {errors?.vehicle_driver_allowed_fio2?.message ||
                            "Ошибка"}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                <div className={styles.box}>
                  <h1>Дата рождения:</h1>
                  <div className={styles.item + " " + styles.secondForm}>
                    <input
                      id={styles.vehicle_driver_allowed_dob2}
                      type="text"
                      onFocus={(e) => (e.target.type = "date")}
                      onBlur={(e) => (e.target.type = "text")}
                      placeholder="00.00.0000"
                      {...register("vehicle_driver_allowed_dob2", {
                        required: isDriverInputSecondFilled
                          ? "Поле обязательно к заполнению"
                          : undefined,
                      })}
                      readOnly={!isDriverInputSecondFilled}
                    />
                    <div>
                      {errors?.vehicle_driver_allowed_dob2 && (
                        <p
                          style={{
                            color: "red",
                            marginTop: "10px",
                            marginBottom: "10px",
                          }}
                        >
                          {errors?.vehicle_driver_allowed_dob2?.message ||
                            "Ошибка"}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                <div className={styles.box}>
                  <h1>Стаж:</h1>
                  <div className={styles.item + " " + styles.secondForm}>
                    <input
                      id={styles.vehicle_driver_allowed_experience_year2}
                      type="text"
                      onFocus={(e) => (e.target.type = "date")}
                      onBlur={(e) => (e.target.type = "text")}
                      placeholder="00.00.0000"
                      {...register("vehicle_driver_allowed_experience_year2", {
                        required: isDriverInputSecondFilled
                          ? "Поле обязательно к заполнению"
                          : null,
                      })}
                      readOnly={!isDriverInputSecondFilled}
                    />
                    <div>
                      {errors?.vehicle_driver_allowed_experience_year2 && (
                        <p
                          style={{
                            color: "red",
                            marginTop: "10px",
                            marginBottom: "10px",
                          }}
                        >
                          {errors?.vehicle_driver_allowed_experience_year2
                            ?.message || "Ошибка"}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                <div className={styles.box}>
                  <h1>№ водит. прав:</h1>
                  <div className={styles.item + " " + styles.fourthForm}>
                    <input
                      id={styles.vehicle_driver_allowed_experience_license2}
                      type="text"
                      placeholder="00 00 000000"
                      onInput={handleInput}
                      {...register(
                        "vehicle_driver_allowed_experience_license2",
                        {
                          required: isDriverInputSecondFilled
                            ? "Поле обязательно к заполнению"
                            : null,
                          pattern: {
                            value: /^[a-zA-Z0-9][a-zA-Z0-9]*$/,
                            message: "Введите на латинице",
                          },
                          validate: validateUniqueDriverLicenses,
                        }
                      )}
                      readOnly={!isDriverInputSecondFilled}
                    />

                    <div>
                      {errors?.vehicle_driver_allowed_experience_license2 && (
                        <p
                          style={{
                            color: "red",
                            marginTop: "10px",
                            marginBottom: "10px",
                          }}
                        >
                          {errors?.vehicle_driver_allowed_experience_license2
                            ?.message || "Ошибка"}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.driverInput}>
              <div className={styles.inputBlock}>
                <div className={styles.box}>
                  <h1>ФИО:</h1>
                  <div className={styles.item + " " + styles.firstForm}>
                    <input
                      id={styles.vehicle_driver_allowed_fio3}
                      type="text"
                      placeholder="Фамилия Имя Отчество"
                      {...register("vehicle_driver_allowed_fio3", {
                        pattern: {
                          value: /^[\WА-Яа-я]+$/i,
                          message:
                            "Введите только на кириллице! Символы и цифры нельзя!",
                        },
                        minLength: {
                          value: 3,
                          message: "Минимум 3 букв",
                        },
                      })}
                      onChange={(e) => {
                        if (e.target.value.trim() !== "") {
                          setIsDriverInputThirdFilled(true);
                        } else {
                          setIsDriverInputThirdFilled(false);
                          setValue("vehicle_driver_allowed_fio3", null);
                          setValue("vehicle_driver_allowed_dob3", null);
                          setValue(
                            "vehicle_driver_allowed_experience_year3",
                            null
                          );
                          setValue(
                            "vehicle_driver_allowed_experience_license3",
                            null
                          );
                        }
                      }}
                    />
                    <div>
                      {errors?.vehicle_driver_allowed_fio3 && (
                        <p
                          style={{
                            color: "red",
                            marginTop: "10px",
                            marginBottom: "10px",
                          }}
                        >
                          {errors?.vehicle_driver_allowed_fio3?.message ||
                            "Ошибка"}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                <div className={styles.box}>
                  <h1>Дата рождения:</h1>
                  <div className={styles.item + " " + styles.secondForm}>
                    <input
                      id={styles.vehicle_driver_allowed_dob3}
                      type="text"
                      onFocus={(e) => (e.target.type = "date")}
                      onBlur={(e) => (e.target.type = "text")}
                      placeholder="00.00.0000"
                      {...register("vehicle_driver_allowed_dob3", {
                        required: isDriverInputThirdFilled
                          ? "Поле обязательно к заполнению"
                          : null,
                      })}
                      readOnly={!isDriverInputThirdFilled}
                    />
                    <div>
                      {errors?.vehicle_driver_allowed_dob3 && (
                        <p
                          style={{
                            color: "red",
                            marginTop: "10px",
                            marginBottom: "10px",
                          }}
                        >
                          {errors?.vehicle_driver_allowed_dob3?.message ||
                            "Ошибка"}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                <div className={styles.box}>
                  <h1>Стаж:</h1>
                  <div className={styles.item + " " + styles.secondForm}>
                    <input
                      id={styles.vehicle_driver_allowed_experience_year3}
                      type="text"
                      onFocus={(e) => (e.target.type = "date")}
                      onBlur={(e) => (e.target.type = "text")}
                      placeholder="00.00.0000"
                      {...register("vehicle_driver_allowed_experience_year3", {
                        required: isDriverInputThirdFilled
                          ? "Поле обязательно к заполнению"
                          : null,
                      })}
                      readOnly={!isDriverInputThirdFilled}
                    />
                    <div>
                      {errors?.vehicle_driver_allowed_experience_year3 && (
                        <p
                          style={{
                            color: "red",
                            marginTop: "10px",
                            marginBottom: "10px",
                          }}
                        >
                          {errors?.vehicle_driver_allowed_experience_year3
                            ?.message || "Ошибка"}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                <div className={styles.box}>
                  <h1>№ водит. прав:</h1>
                  <div className={styles.item + " " + styles.fourthForm}>
                    <input
                      id={styles.vehicle_driver_allowed_experience_license3}
                      type="text"
                      placeholder="00 00 000000"
                      onInput={handleInput}
                      {...register(
                        "vehicle_driver_allowed_experience_license3",
                        {
                          required: isDriverInputThirdFilled
                            ? "Поле обязательно к заполнению"
                            : null,
                          pattern: {
                            value: /^[a-zA-Z0-9][a-zA-Z0-9]*$/,
                            message: "Введите на латинице",
                          },
                          validate: validateUniqueDriverLicenses,
                        }
                      )}
                      readOnly={!isDriverInputThirdFilled}
                    />
                    <div>
                      {errors?.vehicle_driver_allowed_experience_license3 && (
                        <p
                          style={{
                            color: "red",
                            marginTop: "10px",
                            marginBottom: "10px",
                          }}
                        >
                          {errors?.vehicle_driver_allowed_experience_license3
                            ?.message || "Ошибка"}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.driverInputSecond}>
              <div className={styles.inputBlock}>
                <div className={styles.box}>
                  <h1>ФИО:</h1>
                  <div className={styles.item + " " + styles.firstForm}>
                    <input
                      id={styles.vehicle_driver_allowed_fio4}
                      type="text"
                      placeholder="Фамилия Имя Отчество"
                      {...register("vehicle_driver_allowed_fio4", {
                        pattern: {
                          value: /^[\WА-Яа-я]+$/i,
                          message:
                            "Введите только на кириллице! Символы и цифры нельзя!",
                        },
                        minLength: {
                          value: 3,
                          message: "Минимум 3 букв",
                        },
                      })}
                      onChange={(e) => {
                        if (e.target.value.trim() !== "") {
                          setIsDriverInputFourthFilled(true);
                        } else {
                          setIsDriverInputFourthFilled(false);
                          setValue("vehicle_driver_allowed_fio4", null);
                          setValue("vehicle_driver_allowed_dob4", null);
                          setValue(
                            "vehicle_driver_allowed_experience_year4",
                            null
                          );
                          setValue(
                            "vehicle_driver_allowed_experience_license4",
                            null
                          );
                        }
                      }}
                    />
                    <div>
                      {errors?.vehicle_driver_allowed_fio4 && (
                        <p
                          style={{
                            color: "red",
                            marginTop: "10px",
                            marginBottom: "10px",
                          }}
                        >
                          {errors?.vehicle_driver_allowed_fio4?.message ||
                            "Ошибка"}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                <div className={styles.box}>
                  <h1>Дата рождения:</h1>
                  <div className={styles.item + " " + styles.secondForm}>
                    <input
                      id={styles.vehicle_driver_allowed_dob4}
                      type="text"
                      onFocus={(e) => (e.target.type = "date")}
                      onBlur={(e) => (e.target.type = "text")}
                      placeholder="00.00.0000"
                      {...register("vehicle_driver_allowed_dob4", {
                        required: isDriverInputFourthFilled
                          ? "Поле обязательно к заполнению"
                          : null,
                      })}
                      readOnly={!isDriverInputFourthFilled}
                    />
                    <div>
                      {errors?.vehicle_driver_allowed_dob4 && (
                        <p
                          style={{
                            color: "red",
                            marginTop: "10px",
                            marginBottom: "10px",
                          }}
                        >
                          {errors?.vehicle_driver_allowed_dob4?.message ||
                            "Ошибка"}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                <div className={styles.box}>
                  <h1>Стаж:</h1>
                  <div className={styles.item + " " + styles.secondForm}>
                    <input
                      id={styles.vehicle_driver_allowed_experience_year4}
                      type="text"
                      onFocus={(e) => (e.target.type = "date")}
                      onBlur={(e) => (e.target.type = "text")}
                      placeholder="00.00.0000"
                      {...register("vehicle_driver_allowed_experience_year4", {
                        required: isDriverInputFourthFilled
                          ? "Поле обязательно к заполнению"
                          : null,
                      })}
                      readOnly={!isDriverInputFourthFilled}
                    />
                    <div>
                      {errors?.vehicle_driver_allowed_experience_year4 && (
                        <p
                          style={{
                            color: "red",
                            marginTop: "10px",
                            marginBottom: "10px",
                          }}
                        >
                          {errors?.vehicle_driver_allowed_experience_year4
                            ?.message || "Ошибка"}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                <div className={styles.box}>
                  <h1>№ водит. прав:</h1>
                  <div className={styles.item + " " + styles.fourthForm}>
                    <input
                      id={styles.vehicle_driver_allowed_experience_license4}
                      type="text"
                      placeholder="00 00 000000"
                      onInput={handleInput}
                      {...register(
                        "vehicle_driver_allowed_experience_license4",
                        {
                          required: isDriverInputFourthFilled
                            ? "Поле обязательно к заполнению"
                            : null,
                          pattern: {
                            value: /^[a-zA-Z0-9][a-zA-Z0-9]*$/,
                            message: "Введите на латинице",
                          },
                          validate: validateUniqueDriverLicenses,
                        }
                      )}
                      readOnly={!isDriverInputFourthFilled}
                    />
                    <div>
                      {errors?.vehicle_driver_allowed_experience_license4 && (
                        <p
                          style={{
                            color: "red",
                            marginTop: "10px",
                            marginBottom: "10px",
                          }}
                        >
                          {errors?.vehicle_driver_allowed_experience_license4
                            ?.message || "Ошибка"}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.radioContent}>
        <div className={styles.radioBlock}>
          <h1>Автомобиль зарегистрирован в Кыргызстане?</h1>
          <div className={styles.radioBox}>
            <div>
              <input
                {...register("is_registration_in_kyrgyzstan", {
                  required: "Поле обязательно к заполнению",
                })}
                id={"yes"}
                type="radio"
                value={1}
              />
              <label htmlFor={"yes"}>Да</label>
            </div>
            <div>
              <input
                {...register("is_registration_in_kyrgyzstan", {
                  required: "Поле обязательно к заполнению",
                })}
                id={"no"}
                type="radio"
                value={0}
              />
              <label htmlFor={"no"}>Нет</label>
            </div>
          </div>
          <div>
            {errors?.is_registration_in_kyrgyzstan && (
              <p
                style={{
                  color: "red",
                  marginTop: "10px",
                  marginBottom: "10px",
                }}
              >
                {errors?.is_registration_in_kyrgyzstan?.message || "Ошибка"}
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
