import React, { useState } from "react";
import styles from "./IssuedForm.module.css";
import { useForm, useWatch } from "react-hook-form";
import { toast } from "react-toastify";
import { API_URL } from "../../Сonstants";
// components
import IssuedPolicies from "../IssuedPolicies/IssuedPolicies";
// img
import close from "../../image/Закрыть.svg";
const IssuedForm = ({ closeModal }) => {
  const [data, setData] = useState([]);

  //  useForm
  const {
    register,
    formState: { errors, isValid },
    handleSubmit,
    control,
  } = useForm({
    mode: "onChange",
  });
  const [disabled, setDisabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [issuedPolicies, setIssuedPolicies] = useState(false);

  const credit_agent_username = useWatch({
    control,
    name: "credit_agent_username",
  });
  const credit_agent_password = useWatch({
    control,
    name: "credit_agent_password",
  });

  const onSubmit = () => {
    setDisabled(true);
    setLoading(true);
    var myHeaders = new Headers();
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify({
        credit_agent_username: credit_agent_username,
        credit_agent_password: credit_agent_password,
      }),
      redirect: "follow",
    };
    try {
      fetch(`${API_URL}/list_car_api/`, requestOptions)
        .then((res) => {
          if (res?.status === 401) {
            setLoading(false);
            setDisabled(false);
            return toast.error("Неправильный логин или пароль");
          }
          if (res?.status >= 400) {
            setLoading(false);
            setDisabled(false);
            return toast.error("Полис не найден");
          } else {
            setIssuedPolicies(!issuedPolicies);
            setLoading(false);
            setDisabled(false);
          }
          return res.json();
        })
        .then((data) => {
          setData(data);
          setLoading(false);
          setDisabled(false);
        })
        .catch((err) => {
          toast.error("Форма не отправлена");
          setLoading(false);
          setDisabled(false);
        })
        .finally(() => {
          setLoading(false);
          setDisabled(false);
        });
    } catch (e) {
      toast.error("Ошибка с сервером");
      setLoading(false);
      setDisabled(false);
    }
  };

  return (
    <div className={styles.window}>
      <div className="wrapper">
        <div className={styles.polis}>
          <div className={styles.windowHead}>
            <div className={styles.headText}>
              <h1>Выданные полисы</h1>
            </div>
            <img onClick={closeModal} src={close} alt="close" />
          </div>

          <form onSubmit={handleSubmit(onSubmit)}>
            <div className={styles.polisBlock}>
              <div className={styles.polisItem}>
                <label htmlFor="credit_agent_username">Логин Агента </label>
                <input
                  id={styles.credit_agent_username}
                  type="text"
                  placeholder="ХХхХхХХх"
                  {...register("credit_agent_username", {
                    required: "Поле обязательно к заполнению",
                    pattern: {
                      value: /^[a-zA-Z][a-zA-Z0-9-_\.]/,
                      message: "Неправильный логин: введите на латинице",
                    },
                  })}
                />

                <div>
                  {errors?.credit_agent_username && (
                    <p style={{ color: "red", marginTop: "10px" }}>
                      {errors?.credit_agent_username?.message || "Ошибка"}
                    </p>
                  )}
                </div>
              </div>
              <div className={styles.polisItem}>
                <label htmlFor="credit_agent_password">Пароль</label>
                <input
                  id={styles.credit_agent_password}
                  type="password"
                  placeholder="********"
                  {...register("credit_agent_password", {
                    required: "Поле обязательно к заполнению",
                  })}
                />

                <div>
                  {errors?.credit_agent_password && (
                    <p style={{ color: "red", marginTop: "10px" }}>
                      {errors?.credit_agent_password?.message || "Ошибка"}
                    </p>
                  )}
                </div>
              </div>
              {loading ? (
                <span className="loading"></span>
              ) : (
                <button
                  type="submit"
                  disabled={!isValid || disabled}
                  style={
                    disabled || !isValid
                      ? { opacity: "0.5", cursor: "default" }
                      : { opacity: "1", cursor: "pointer" }
                  }
                >
                  Проверить полис
                </button>
              )}
            </div>
          </form>
        </div>
        {issuedPolicies ? (
          <IssuedPolicies
            closeModal={() => setIssuedPolicies(false)}
            data={data}
          />
        ) : null}
      </div>
    </div>
  );
};

export default IssuedForm;
