// import { Slider } from './Components/Slider'
// React
import React from "react";
import Slider from "react-slick";
import "react-toastify/dist/ReactToastify.css";
// Components
import Services from "./Components/Services";
import Form from "./Components/Form";
import Insurance from "./Components/Insurance";

import Footer from "./Components/Footer";
// Css
import "./App.css";
import "../node_modules/slick-carousel/slick/slick.css";
import "../node_modules/slick-carousel/slick/slick-theme.css";
// Image
import logo1 from "./image/Logo1.svg";
import logo2 from "./image/Logo2.png";
import twitter from "./image/twitter.svg";
import faceBook from "./image/faceBook.svg";
import instagram from "./image/instagram.svg";
import { ToastContainer } from "react-toastify";

const App = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  // const containerStyles = {
  //   width: "100%",
  //   height: "100vh",
  // };

  return (
    <div>
      <Slider {...settings}>
        <div className="slide slide1">
          <div className="logo">
            <a href="https://zdorovie.kg/">
              <img src={logo1} alt="logo" />
            </a>
            <a href="https://mydoctor.kg/">
              <img src={logo2} alt="logo" />
            </a>
          </div>

          <div className="slideText container">
            <h1>
              врачи на видео-связи по <br /> всему кыргызстану
            </h1>
            <p>Круглосуточная служба экстренных консультаций терапевта</p>
          </div>

          <div className="social">
            <a href="#">
              <img src={twitter} alt="twitter" />
            </a>
            <a href="#">
              <img src={faceBook} alt="faceBook" />
            </a>
            <a href="#">
              <img src={instagram} alt="instagram" />
            </a>
          </div>
        </div>
        <div className="slide slide2">
          <div className="slideText">
            <h1>
              врачи на видео-связи по <br /> всему кыргызстану
            </h1>
            <p>Круглосуточная служба экстренных консультаций терапевта</p>
          </div>
        </div>
        <div className="slide slide3">
          <div className="slideText">
            <h1>
              врачи на видео-связи по <br /> всему кыргызстану
            </h1>
            <p>Круглосуточная служба экстренных консультаций терапевта</p>
          </div>
        </div>
      </Slider>
      {/* <div className="container"> */}
      <Services />
      <Form />
      <Insurance />
      <Footer />
      <ToastContainer />
    </div>
  );
};
export default App;
