import React, { useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import "./MultiStepForm.css";
import { toast } from "react-toastify";
// mui
import { styled } from "@mui/material/styles";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import PersonForm from "./PersonForm/PersonForm";
// components
import { API_URL } from "../../Сonstants";
import TechCertificateForm from "./TechCertificateForm/TechCertificateForm";
import DriversForm from "./DriversForm/DriversForm";
import TypeTransportForm from "./TypeTransport/TypeTransportForm";
import ResultForm from "./ResultForm/ResultForm";

const StyledStepper = styled(Stepper)`
  .MuiStepIcon-root {
    width: 56px;
    height: 56px;
    color: #d9d9d9;
    z-index: 1;
  }
  .Mui-active {
    color: #00ccff !important;
  }
  .MuiStepConnector-root {
    top: 25px;
  }
  .Mui-completed {
    color: #00ccff !important;
  }
  .MuiStepConnector-line {
    border-top-width: 4px;
    border-color: #d9d9d9;
  }
  @media screen and (max-width: 500px) {
    .MuiStepIcon-root {
      width: 30px;
      height: 30px;
    }
    .MuiStepConnector-root {
      top: 15px;
    }
    .MuiStepConnector-line {
      border-top-width: 2px;
    }
  }
`;
const StyledButton = styled(Button)({
  backgroundColor: "#ffffff",
  border: "1px solid #d9d9d9",
  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.15)",
  borderRadius: "8px",
  padding: "20px 100px",
  fontWeight: 400,
  fontSize: "20px",
  textTransform: "uppercase",
  color: "#d9d9d9",
  margin: 0,
  "&:hover": {
    backgroundColor: "#00ccff",
    color: "#fff",
  },
  "@media (max-width: 950px)": {
    padding: "10px 50px",
  },
});
const BackButton = styled(Button)({
  color: "#00ccff",
  marginLeft: 0,
  marginTop: 0,
  marginRight: "10px",
});

const MultiStepForm = () => {
  //useForm
  const methods = useForm();
  const steps = getSteps();
  const [activeStep, setActiveStep] = useState(0);
  const [result, setResult] = useState(0);
  const [clientCode, setСlientCode] = useState(0);
  const [savedData, setSavedData] = useState(null);
  const [loading, setLoading] = useState(null);

  function getSteps() {
    return ["", "", "", "", ""];
  }

  function getStepContent(step) {
    switch (step) {
      case 0:
        return <TypeTransportForm handleInput={handleInput} />;
      case 1:
        return <PersonForm />;
      case 2:
        return <TechCertificateForm handleInput={handleInput} />;
      case 3:
        return <DriversForm handleInput={handleInput} />;
      case 4:
        return <ResultForm clientCode={clientCode} result={result} />;
      default:
        return "Ошибка! Попробуйте снова.";
    }
  }

  const handleInput = (event) => {
    event.target.value = event.target.value.toUpperCase();
  };

  const onSubmit = async (data) => {
    // parseInt

    data.car_type = parseInt(data.car_type);
    data.is_diagnostic_card_exist = parseInt(data.is_diagnostic_card_exist);
    data.car_engine_capacity = parseInt(data.car_engine_capacity);
    data.car_maximum_mass = parseInt(data.car_maximum_mass);
    data.car_seats_count = parseInt(data.car_seats_count);
    data.motor_power = parseInt(data.motor_power);
    data.is_registration_in_kyrgyzstan = parseInt(
      data.is_registration_in_kyrgyzstan
    );
    // empty
    const emptyString = {};
    Object.keys(data).forEach((item) => {
      if (data[item] === "") {
        emptyString[item] = null;
      } else {
        emptyString[item] = data[item];
      }
    });
    if (activeStep === steps.length - 2) {
      var myHeaders = new Headers();
      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify(emptyString),
        redirect: "follow",
      };

      try {
        const response = await fetch(
          `${API_URL}/check_final_sum/`,
          requestOptions
        );

        if (response.ok) {
          const data = await response.json(); // Прочитать данные только здесь

          setSavedData(data.car_ins);
          setResult(data.sum);
          setСlientCode(data.client_code);
          setActiveStep(activeStep + 1);
        } else if (response.status >= 400 && response.status < 500) {
          // Обработка ошибок 400 (клиентская ошибка)
          const errorData = await response.json(); // Прочитать данные только здесь
          toast.error("Ошибка на стороне клиента: " + errorData.message);
        } else if (response.status >= 500) {
          // Обработка ошибок 500 (ошибка сервера)
          toast.error("Ошибка сервера");
        } else {
          toast.error("Неизвестная ошибка");
        }
      } catch (error) {
        toast.error("Произошла ошибка при отправке запроса");
      }
    } else {
      setActiveStep(activeStep + 1);
    }
  };
  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };
  const onSubmitFinalStep = async () => {
    setLoading(true);
    var myHeaders = new Headers();
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(savedData),
      redirect: "follow",
    };

    try {
      const response = await fetch(`${API_URL}/car_create/`, requestOptions);

      if (response.ok) {
        setLoading(false);
        const data = await response.json(); // Прочитать данные только здесь

        setСlientCode(data.client_code);
        toast.success("Форма успешно отправлена");
      } else if (response.status >= 400 && response.status < 500) {
        // Обработка ошибок 400 (клиентская ошибка)
        toast.error("Некорректные данные");
        setLoading(false);
      } else if (response.status >= 500) {
        // Обработка ошибок 500 (ошибка сервера)
        toast.error("Ошибка сервера");
        setLoading(false);
      } else {
        toast.error("Неизвестная ошибка");
        setLoading(false);
      }
    } catch (error) {
      toast.error("Произошла ошибка при отправке запроса");
      setLoading(false);
    }
  };
  return (
    <div className="multiForm">
      <StyledStepper alternativeLabel activeStep={activeStep}>
        {steps.map((step, index) => {
          const labelProps = {};
          const stepProps = {};

          return (
            <Step {...stepProps} key={index}>
              <StepLabel {...labelProps}>{step}</StepLabel>
            </Step>
          );
        })}
      </StyledStepper>

      {activeStep === steps.length ? (
        <div className="resultStep">
          <h1 variant="h1" align="center">
            Ваш полис!
          </h1>
          {loading ? (
            <span className="loading"></span>
          ) : (
            <h2 className="clientCode">{clientCode}</h2>
          )}
        </div>
      ) : (
        <>
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              {getStepContent(activeStep)}
              <div className="formBtn">
                {activeStep === 4 ? (
                  <button onClick={handleBack} className="cancelBtn">
                    Отмена
                  </button>
                ) : (
                  <BackButton disabled={activeStep === 0} onClick={handleBack}>
                    НАЗАД
                  </BackButton>
                )}

                {activeStep === 4 ? (
                  <button
                    onClick={() => onSubmitFinalStep(methods.getValues())}
                    className="saveBtn"
                  >
                    Продолжить
                  </button>
                ) : (
                  <StyledButton variant="contained" type="submit">
                    Далее
                  </StyledButton>
                )}
              </div>
            </form>
          </FormProvider>
        </>
      )}
    </div>
  );
};

export default MultiStepForm;
