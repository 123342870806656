import React, { useState } from "react";
import styles from "./LastResult.module.css";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { API_URL } from "../../Сonstants";
// components

// img
import close from "../../image/Закрыть.svg";
const SearchPolisies = ({
  closeModal,
  resultSum,
  vehicle_doc_sequence,
  closeForm,
}) => {
  //  useForm
  const {
    formState: { isValid },
    handleSubmit,
  } = useForm({
    mode: "onChange",
  });
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const onSubmit = (data) => {
    setDisabled(true);
    setLoading(true);
    // empty
    const emptyString = {};
    Object.keys(data).forEach((item) => {
      if (data[item] === "") {
        emptyString[item] = null;
      } else {
        emptyString[item] = data[item];
      }
    });

    var getMyHeaders = new Headers();

    getMyHeaders.append("Content-Type", "application/json");
    getMyHeaders.append("Authorization", "Basic YWRtaW46YWRtaW4=");

    var getRequestOptions = {
      method: "GET",
      headers: getMyHeaders,
      redirect: "follow",
    };

    try {
      fetch(
        `${API_URL}/car_get_doc?vehicle_doc_sequence=${vehicle_doc_sequence}`,
        getRequestOptions
      )
        .then(async (res) => {
          // FileDownload(await res.blob(), "asd3.pdf")
          const data = await res.blob();
          const url = window.URL.createObjectURL(
            new Blob([data], { type: "application/pdf" })
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("target", "_blank");
          document.body.appendChild(link);
          link.click();
          // Clean up and remove the link
          link.parentNode.removeChild(link);
          setLoading(false);
          setDisabled(false);

          // closeModal(resultSum);
          closeModal(false);
          closeForm(closeForm);
        })

        .catch((err) => {
          toast.error("Форма не отправлена");
          setDisabled(false);
          setLoading(false);
        })
        .finally(() => {
          setDisabled(false);
          setLoading(false);
        });
    } catch (e) {
      toast.error("Ошибка с сервером");
      setDisabled(false);
      setLoading(false);
    }
  };

  return (
    <div className={styles.window}>
      <div className="container">
        <div className={styles.polis}>
          <div className={styles.windowHead}>
            <div>
              <h1>Оплата:</h1>
            </div>
            <img onClick={closeModal} src={close} alt="close" />
          </div>

          <form onSubmit={handleSubmit(onSubmit)}>
            <div className={styles.resultContent}>
              <div className={styles.result}>
                <h2>
                  {resultSum} <span>сом</span>{" "}
                </h2>
              </div>

              {loading ? (
                <span className="loading"></span>
              ) : (
                <button
                  type="submit"
                  disabled={!isValid || disabled}
                  style={
                    disabled || !isValid
                      ? { opacity: "0.5", cursor: "default" }
                      : { opacity: "1", cursor: "pointer" }
                  }
                >
                  Активировать
                </button>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default SearchPolisies;
