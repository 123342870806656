import React from "react";
import styles from "./ResultForm.module.css";
const ResultForm = ({ result }) => {
  return (
    <div className={styles.resultBlock}>
      <div className={styles.result}>
        <h1>Сумма к оплате:</h1>
        <h2>
          {result} <span>сом</span>
        </h2>
      </div>
    
    </div>
  );
};

export default ResultForm;
