import React, { useState, useRef } from "react";
import { useForm, useWatch } from "react-hook-form";
import ReCAPTCHA from "react-google-recaptcha";
import styles from "./Insurance.module.css";
import mobile1 from "../image/smart1.png";
import mobile2 from "../image/smart2.png";
import playMarket from "../image/playMarket.png";
import appStore from "../image/appStore.png";
import arrow from "../image/Стрела.svg";
import Modal from "./Modal";
import { toast } from "react-toastify";
import { API_URL } from "../Сonstants";

// pdf
import pdf1 from "../PDF/Памятка_по_получению_страхового_возмещения.pdf";
import pdf2 from "../PDF/Заявление_на_получение_страх_выплаты.pdf";
import pdf3 from "../PDF/Правила_комплексного_страхования.pdf";
import pdf4 from "../PDF/Правила_добровольного_медицинского_страхования.pdf";
import pdf5 from "../PDF/Правила_страхования_профессиональной_ответственности.pdf";
import pdf6 from "../PDF/Свидетельство о регистрации.pdf";

const DATA_SITE_KEY = "6LcHLd0hAAAAAF88gqhTgchQHA--p390CZCe1-_A";
function Insurance() {
  // keyReCaptcha

  const captchaRef = useRef(null);

  const [fio, setFio] = useState("");
  const [date_from, setDate_from] = useState("");
  const [date_to, setDate_to] = useState("");
  const [balance, setBalance] = useState("");
  const [policyNumber, setPolicyNumber] = useState("");
  const [modalActive, setModalActive] = useState(false);
  const [captcha, setCaptcha] = useState(false);
  //  useForm
  const {
    register,
    formState: { errors, isValid },
    handleSubmit,
    reset,
    control,
  } = useForm({
    mode: "onChange",
  });
  const [disabled, setDisabled] = useState(false);
  const [loading, setLoading] = useState(false);

  // get
  const search = useWatch({
    control,
    name: "search",
  });

  const onSubmit = () => {
    setDisabled(true);
    setLoading(true);
    var getMyHeaders = new Headers();

    getMyHeaders.append("Content-Type", "application/json");

    var getRequestOptions = {
      method: "GET",
      headers: getMyHeaders,
    };

    fetch(`${API_URL}/dms_search_api?policyNumber=${search}`, getRequestOptions)
      .then((res) => {
        if (res?.status >= 400) {
          setLoading(false);
          setDisabled(false);
          return toast.error("Полис не найден");
        } else {
          setLoading(false);
          setDisabled(false);
          setModalActive(!modalActive);
        }
        return res.json();
      })
      .then((data) => {
        setPolicyNumber(data.policyNumber);
        setFio(data.fio);
        setDate_from(data.date_from);
        setDate_to(data.date_to);
        setBalance(data.balance);
        setLoading(false);
        setDisabled(false);
      })
      .catch((err) => {
        setLoading(false);
        setDisabled(false);

        toast.error("Ошибка с сервером");
      })
      .finally(() => {
        setLoading(false);
        setDisabled(false);
      });
  };
  // firefox
  const checkIfNumber = (event) => {
    const regex = new RegExp(
      /(^\d*$)|(Backspace|Tab|Delete|ArrowLeft|ArrowRight)/
    );
    return !event.key.match(regex) && event.preventDefault();
  };
  return (
    <div className={styles.insurance}>
      <div className="container">
        <h1>медицинское страхование дмс</h1>
        <div className={styles.insuranceBlock}>
          <div className={styles.mobiMarket}>
            <div className={styles.marketBlock}>
              <a href="#">
                <img src={playMarket} alt="playMarket" />
              </a>
              <a href="#">
                <img src={appStore} alt="appStore" />
              </a>
            </div>
            <div className={styles.mobileImg}>
              <img src={mobile2} alt="mobile" />
              <img src={mobile1} alt="mobile" />
            </div>
          </div>

          <div className={styles.insuranceText}>
            <p>
              Получайте возмещение понесенных расходов согласно предоставленным
              чекам
            </p>
            <p>
              Круглосуточная служба врачей-координаторов проконсультирует и
              направит в удобную клинику
            </p>
            <p>Возможность безналичной оплаты в клиниках-партнерах</p>
            <p>
              Полис включает в себя доступ в приложение мой доктор на год +
              неограниченные видео-консультации терапевта/педиатра
            </p>
            <p>
              Отслеживайте страховые лимиты и произведенные выплаты в мобильном
              приложении
            </p>
            <div className={styles.appMarket}>
              <a href="https://play.google.com/store/apps/details?id=com.architech.insuranceAppflut">
                <img src={playMarket} alt="playMarket" />
              </a>
              <a href="#">
                <img src={appStore} alt="appStore" />
              </a>
            </div>
          </div>
        </div>
        <div className={styles.background}></div>

        <div className={styles.polis}>
          <h1>проверить полис дмс</h1>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className={styles.polisBlock}>
              <input
                onInput={(e) => {
                  if (e.target.value.length > e.target.maxLength)
                    e.target.value = e.target.value.slice(
                      0,
                      e.target.maxLength
                    );
                }}
                style={
                  errors?.search
                    ? { marginBottom: "10px" }
                    : { marginBottom: "25px" }
                }
                onKeyDown={(event) => checkIfNumber(event)}
                maxLength={6}
                type="number"
                placeholder="ДМС - Х000"
                {...register("search", {
                  required: "Поле обязательно к заполнению",
                  minLength: {
                    value: 6,
                    message: "Введите 6 цифр",
                  },
                  maxLength: {
                    value: 6,
                    message: "Неккоректный номер полиса",
                  },
                  pattern: {
                    value: /[0-9]/,
                  },
                })}
              />
              <div>
                {errors?.search && (
                  <p
                    style={{
                      color: "red",
                      marginBottom: "15px",
                    }}
                  >
                    {errors?.search?.message || "Такого логина не существует"}
                  </p>
                )}
              </div>

              <ReCAPTCHA
                sitekey={DATA_SITE_KEY}
                ref={captchaRef}
                onChange={(value) => setCaptcha((p) => !p)}
              />

              {loading ? (
                <span className="loading"></span>
              ) : (
                <button
                  type="submit"
                  disabled={!isValid || disabled}
                  style={
                    disabled || !isValid
                      ? { opacity: "0.5", cursor: "default" }
                      : { opacity: "1", cursor: "pointer" }
                  }
                >
                  Проверить полис
                </button>
              )}
            </div>
          </form>
        </div>
        <div className={styles.pdf}>
          <div>
            <h2>
              <a href={pdf1} target="_blank">
                памятка по получению страхового возмещения (PDF)
              </a>
            </h2>

            <a href={pdf1} target="_blank">
              <img src={arrow} alt="arrow" />
            </a>
          </div>
          <div>
            <h2>
              {" "}
              <a href={pdf2} target="_blank">
                заявление на страховое возмещение(pdf)
              </a>
            </h2>

            <a href={pdf2} target="_blank">
              <img src={arrow} alt="arrow" />
            </a>
          </div>
          <div>
            <h2>
              {" "}
              <a href={pdf3} target="_blank">
                правила комплексного страхования(PDF)
              </a>
            </h2>

            <a href={pdf3} target="_blank">
              <img src={arrow} alt="arrow" />
            </a>
          </div>
          <div>
            <h2>
              {" "}
              <a href={pdf4} target="_blank">
                правила добровольного медицинского страхования(pdf)
              </a>
            </h2>

            <a href={pdf4} target="_blank">
              <img src={arrow} alt="arrow" />
            </a>
          </div>
          <div>
            <h2>
              <a href={pdf5} target="_blank">
                ПРАВИЛА СТРАХОВАНИЯ ПРОФ. ОТВЕТСТВЕННОСТИ(pdf)
              </a>
            </h2>

            <a href={pdf5} target="_blank">
              <img src={arrow} alt="arrow" />
            </a>
          </div>
          <div>
            <h2>
              <a href={pdf6} target="_blank">
                свидетельство о регистрации
              </a>
            </h2>

            <a href={pdf6} target="_blank">
              <img src={arrow} alt="arrow" />
            </a>
          </div>
        </div>
      </div>

      {modalActive ? (
        <Modal
          fio={fio}
          date_from={date_from}
          balance={balance}
          date_to={date_to}
          policyNumber={policyNumber}
          closeModal={() => setModalActive(false)}
        />
      ) : null}
    </div>
  );
}

export default Insurance;
